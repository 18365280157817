<template>
    <div class="page">
        <div class="page__path">
            <!-- <img src="/background-path.png" class="page__path"> -->
            <div class="d-flex align-items-md-center">
                <nuxt />
                <toast-container-component />
            </div>

        </div>
    </div>
</template>
<script>
import ToastContainerComponent from '~/components/toast-container.vue'
export default {
    middleware: [
        'outdatedBrowser',
        // 'verified',
    ],

    components: {
        ToastContainerComponent
    }
}
</script>