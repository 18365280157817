import Vue from 'vue'
import Mousetrap from 'mousetrap'

export default ({ store }) => {
    // Global Keybindings
    Mousetrap.bind(['command+k', 'ctrl+k'], () => {
        if (store.getters['user/loggedIn']) {
            store.dispatch('search/toggleOverlay')
            return false
        }
    })

    Vue.mixin({
        data() {
            return {
                keyboardBindings: []
            }
        },
    
        methods: {
            $bindKeyboard(config) {
                const keys = Object.keys(config || {})
                keys
                    .filter(key => !this.keyboardBindings.includes(key))
                    .forEach(key => {
                        this.keyboardBindings.push(key)
                        Mousetrap.bind(key, config[key])
                    })
            },
        },
    
        deactivated() {
            (this.keyboardBindings || []).forEach(key => {
                Mousetrap.unbind(key)
            })

            this.keyboardBindings = []
        },

        beforeDestroy() {
            (this.keyboardBindings || []).forEach(key => {
                Mousetrap.unbind(key)
            })

            this.keyboardBindings = []
        }
    })
}