export default function ({ store, redirect }) {
	const isOrg = store.state.user.user ? store.state.user.user.current_organization : null;

	if (!isOrg) {
		if (process.client) {
			window.history.back();
		} else {
			return redirect('/404');
		}
	}
}
