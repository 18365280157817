<template>
    <div class="form-group" :class="{'form-group--boolean' : booleanMode}">
        <label v-if="label" :for="`form-radio-${label}`">{{ label }}</label>

        <div v-if="booleanMode" class="d-flex justify-content-center">
            <div class="custom-control custom-radio custom-radio--boolean d-inline-block mr-3">
                <input :id="`form-radio-${label}-${index}`"
                    type="radio" 
                    class="custom-control-input" 
                    value="yes"
                    :checked="value == 'yes'"
                    @input="$emit('input', $event.target.value)">
                <label :for="`form-radio-${label}-${index}`" class="custom-control-label">
                    <slot :id="`form-radio-${label}-${index}`">
                        <o-icon :icon="['far', 'thumbs-up']" size="lg" />
                    </slot>
                </label>
            </div>
            <div class="custom-control custom-radio custom-radio--boolean d-inline-block mr-3">
                <input :id="`form-radio-${label}-${index}`"
                    type="radio" 
                    class="custom-control-input" 
                    value="no"
                    :checked="value == 'no'"
                    @input="$emit('input', $event.target.value)">
                <label :for="`form-radio-${label}-${index}`" class="custom-control-label">
                    <slot :id="`form-radio-${label}-${index}`">
                        <o-icon :icon="['far', 'thumbs-down']" size="lg" />
                    </slot>
                </label>
            </div>
        </div>
        
        <div v-else v-for="(option, index) in options" :key="`radio-option-${index}`" class="custom-control custom-radio" :class="{ 'd-inline-block mr-3': inline }">
            <input :id="`form-radio-${label}-${index}`"
                type="radio" 
                class="custom-control-input" 
                :value="option[valueField]"
                :checked="option[valueField] == value"
                @input="$emit('input', $event.target.value)">
            
            <label :for="`form-radio-${label}-${index}`" class="custom-control-label">
                <slot 
                :id="`form-radio-${label}-${index}`"
                :option="option">
                    {{ option[labelField] }}
                </slot>
            </label>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            default: null,
        },
        value: {
            default: null,
        },
        options: {
            type: Array,
            default: []
        },
        labelField: {
            type: String,
            default: 'label'
        },
        valueField: {
            type: String,
            default: 'value'
        },
        inline: {
            type: Boolean,
            default: false,
        },
        booleanMode: {
            type: Boolean,
            default: false,
        }
    }
}
</script>