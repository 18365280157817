<template>
    <o-modal
        :title="title"
        :type="type"
        :confirmText="confirmText"
        :cancelText="cancelText"
        @close="$emit('close')"
        @confirm="$emit('confirm')">
        <div v-html="message"></div>
    </o-modal>
</template>
<script>
export default {
    props: ['title', 'message', 'type', 'confirmText', 'cancelText']
}
</script>