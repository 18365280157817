import Vue from 'vue'
import BaseModal from '~/components/ui/modals/base'

export default ({ app, store }) => {
    const createModal = (props = {}, component = BaseModal) => {
        if (typeof component === 'string') {
            component = require(`~/components/ui/modals/${component}.vue`).default
        }

        return new Promise((resolve, reject) => {
            const ModalComponent = new Vue({
                render(h) {
                    return h(component, {
                        props: {
                            app: app,
                            confirmText: app.i18n.t('crud.ok'),
                            cancelText: app.i18n.t('crud.cancel'),
                            '$t': (key) => app.i18n.t(key),
                            ...props
                        },
                        on: {
                            confirm: ($event) => {
                                resolve($event)
                                ModalComponent.$destroy()
                                ModalComponent.$el.remove()
                            },
                            close: ($event) => {
                                ModalComponent.$destroy()
                                ModalComponent.$el.remove()
                            }
                        }
                    })
                }
            })

            ModalComponent.$store = store
            ModalComponent.i18n = app.i18n
            
            ModalComponent.$mount()
            document.body.appendChild(ModalComponent.$el)
        })
    }

    Vue.prototype.$modal = createModal
}