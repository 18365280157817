import Vue from 'vue'
import { isTouchDevice } from '~/util/featureDetect'

import '~/directives/tooltip'
import '~/directives/clickOutside'
import '~/directives/longPress'

import { format, formatDistanceToNow } from 'date-fns'
import { de as deDateLocale, enGB as enDateLocale } from 'date-fns/locale'

const dateLocales = {
  en: enDateLocale,
  de: deDateLocale
}

export default ({ store }) => {
  Vue.mixin({
    data() {
      return {
        debounceStack: []
      }
    },

    computed: {
      $filterCriteria() {
        return store.state.filterCriteria.filterCriteria
      },

      $applicantProfile() {
        return store.state.applicantProfile.applicantProfile
      },

      $user() {
        return store.state.user.user
      },

      $org() {
        return store.state.user.user
          ? store.state.user.user.current_organization
          : null
      },

      $isTouchDevice() {
        return isTouchDevice()
      },

      $canShare() {
        return navigator.share
      }
    },

    methods: {
      $toast(title, type = null, options = {}) {
        store.dispatch('toast/push', {
          title,
          type,
          ...options
        })
      },

      $getMatchClasses(match = null, criteria = null) {
        if (match && criteria) {
          return match.percents[criteria] && match.percents[criteria] > 0
            ? 'matched'
            : 'not-matched'
        }

        return
      },

      $formatDate(date, f = 'dd.MM.yyyy') {
        if (!date) {
          return null
        }

        if (typeof date === 'string') {
          date = date
            .replace(/-/g, '/')
            .replace('T', ' ')
            .slice(0, 19)
          date = new Date(date)
        }

        return format(date, f, {
          locale: dateLocales[store.state.locale]
        })
      },

      $formatDateTime(date, f = 'dd.MM.yyyy kk:mm') {
        return this.$formatDate(date, f)
      },

      // $formatCurrency(number) {
      //   return new Intl.NumberFormat('de-DE').format(number).replace('.', "'")
      // },

      $dateForDB(date) {
        if (date === 'now') {
          date = new Date()
        }

        return this.$formatDate(date, 'yyyy-MM-dd kk:mm:ss')
      },

      $formatDateDistance(date) {
        if (typeof date === 'string') {
          date = new Date(date)
        }

        return formatDistanceToNow(date, {
          addSuffix: true,
          locale: dateLocales[store.state.locale]
        })
      },

      $formatCurrency(value, precision = 2) {
        if (!value) {
          value = 0
        }

        if (typeof value === 'string') {
          value = parseFloat(value)
        }

        return value.toLocaleString('de-CH', {
          minimumFractionDigits: precision
        })
      },

      $nl2br(str) {
        if (!str) {
          return ''
        }

        const replaceStr = '$1<br>$2'
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr)
      },

      $stripTags(html) {
        let div = document.createElement('div')
        div.innerHTML = html
        return div.textContent || div.innerText || ''
      },

      $breakpoint(breakpoint) {
        const breakpoints = {
          xs: 0,
          sm: 576,
          md: 768,
          lg: 992,
          xl: 1200,
          hd: 1440
        }

        return window.matchMedia(
          `screen and (min-width: ${breakpoints[breakpoint]}px)`
        ).matches
      },

      $debounce(delay, key = 'global') {
        return new Promise(resolve => {
          clearTimeout(this.debounceStack[key])
          this.debounceStack[key] = setTimeout(resolve, delay)
        })
      },

      $cssVar(variable) {
        return getComputedStyle(window.document.documentElement)
          .getPropertyValue('--' + variable)
          .trim()
      },

      async $share(text, url, successMessage = null) {
        return navigator
          .share({
            title: 'apropos.jobs',
            text,
            url
          })
          .then(() => {
            this.$toast(successMessage, 'success')
          })
          .catch(error => {
            // do nothing
          })
      },

      $personalityResultsInPercent(results) {
        const personalitiesCount = {}
        const personalities = results

        personalities.forEach(personality => {
          if (!personalitiesCount[personality]) {
            personalitiesCount[personality] = 1
          } else {
            personalitiesCount[personality] =
              personalitiesCount[personality] + 1
          }
        })

        const personalityInPercentages = []

        Object.keys(personalitiesCount).forEach(personality => {
          let percent = (100 / results.length) * personalitiesCount[personality]

          personalityInPercentages.push({
            personality: personality,
            percent: Math.round(percent * 100) / 100
          })
        })

        return personalityInPercentages.sort(function(a, b) {
          return b.percent - a.percent
        })
      },

      $percentageByKey(key, results, logarithmic = false) {
        var result = this.$personalityResultsInPercent(results).find(obj => {
          return obj.personality == key
        })

        if (typeof result !== 'undefined') {
          if (logarithmic) {
            return Math.log(result.percent*1.5) / Math.log(300)
          } else {
            return Math.round(result.percent)
          }
        }

        return 0
      },

      $getLocaleDbColumn(columnName) {
        const locale = this.$i18n.locale;
        if ( locale === 'de' ) return columnName
        return `${columnName}_${locale}`
      },
    }
  })
}
