<template>
    <div class="app pb-5">
        <div class="brand-line"></div>

        <main>
            <nuxt />
        </main>
    </div>
</template>
<script>
export default {
	head() {
		return {
			meta: [
				{ hid: 'robots', name: 'robots', content: 'index, follow' }
			]
		}
	}
}
</script>
