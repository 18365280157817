<template>
    <o-form-group :label="label" :required="required" :help-text="helpText">
        <div
            v-for="(tag, index) in tags" 
            :key="index" 
            class="badge badge-light mr-1 mb-1">
            <slot 
                :tag="tag" 
                :index="index">
                {{ tag.label }}
            </slot>
            <a href="javascript:;" @click="remove(tag)"><o-icon :icon="['far', 'times']" /></a>
        </div>

        <o-search 
            :options="filteredOptions" 
            class="mt-1" @input="add($event)"
            v-slot="{ option, index }">
            <slot name="option" :option="option" :index="index" />
        </o-search>
    </o-form-group>
</template>
<script>
import OFormGroup from './Group.vue'
import OSearch from './Search.vue'
export default {
    components: {
        OFormGroup,
        OSearch,
    },

    props: {
        value: {
            type: Array,
            default: () => []
        },

        label: {
            type: String,
            default: null,
        },

        helpText: {
            type: String,
            default: null,
        },

        required: {
            type: Boolean,
            default: false,
        },

        options: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            tags: this.value
        }
    },
    
    computed: {
        filteredOptions() {
            return this.options.filter(option => {
                return !this.tags.find(tag => tag.value === option.value)
            })
        }
    },

    methods: {
        add(optionValue) {
            const tag = this.options.find(option => option.value === optionValue)
            const exists = this.tags.find(t => t.value === tag.value)

            if (tag && !exists) {
                this.tags.push(tag)
                this.$emit('input', this.tags)
            }
        },

        remove(tag) {
            this.tags.splice(this.tags.indexOf(tag), 1)
            this.$emit('input', this.tags)
        }
    }
}
</script>