<template>
	<o-modal :title="$t('general.personalities')" size="xl" v-if="personalitiesData">
        <transition-group name="quiz" mode="in-out" style="overflow: hidden;" tag="div">
            <div class="mb-2" v-for="(personality, index) in personalitiesData" :key="`${personality.name}-${index}-${showAll ? 'closed' : 'open'}`">
                <accordion :titleBackdrop="personality.hexColor" :title="$t('personalities.' + personality.name)" :preSelected="!showAll && results">
                    <p>{{ $t('personalities.details.' + personality.name) }}</p>
                </accordion>
            </div>
        </transition-group>
        <o-button v-if="!showAll && results" type="link" @click="showAll = true">
           <u>{{ $t('personality.all-personalities') }}</u>
        </o-button>
	</o-modal>
</template>
<script>
import OModal from "~/components/ui/layout/Modal.vue";
import Accordion from "~/components/ui/common/Accordion"
import Personalities from "@/components/personalities"

export default {
	components: {
		OModal,
        Accordion
    },

    props: {
        title: {
            type: String,
            default: 'Delete Item'
        },
        personality: {
            type: Object,
        },
        '$t': {
            type: Function
        },
        results: {
            type: Array,
            default: null
        }
    },

    data: () => ({
        showAll: false
    }),

    computed: {
        personalitiesData() {
            let data = Personalities
            if (!this.showAll && this.results) {
                data = data.filter(r => this.results.some(v => v == r.name))
            }
            return data
        }
    }
};
</script>
