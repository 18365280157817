const prefix = 'apropos-jobs__'

class Storage {
    constructor(prefix = 'apropos-jobs__') {
        this.prefix = prefix
    }

    set(key, value) {
        try {
            window.localStorage.setItem(prefix + key, JSON.stringify(value))
            return true
        } catch (err) {
            return false
        }
    }

    get(key, fallback = null) {
        const item = window.localStorage.getItem(prefix + key)
        return item ? JSON.parse(item) : fallback
    }

    remove(key) {
        window.localStorage.removeItem(prefix + key)
    }

    flush() {
        window.localStorage.clear()
    }
}

export default new Storage