<template>
  <div class="app d-flex flex-column h-100">
    <div class="pb-4">
      <nuxt keep-alive :keep-alive-props="{ max: 5 }" />
    </div>
    <toast-container-component />
    <footer-component />
  </div>
</template>
<script>
import FooterComponent from '~/components/footer.vue'
import HeaderComponent from '~/components/header.vue'
import ToastContainerComponent from '~/components/toast-container.vue'

export default {
	head() {
		return {
			meta: [
				{ hid: 'robots', name: 'robots', content: 'noindex, nofollow' }
			]
		}
	},

    middleware: [
        'auth',
        // 'verified'
    ],

    components: {
        HeaderComponent,
        FooterComponent,
        ToastContainerComponent,
    }
}
</script>
