import Http from 'axios'

export const actions = {
    /**
     * Store a file in S3 and return its UUID, key, and other information.
     */
    async upload({ commit }, { file, endpoint, onProgress, options = {} }) {
        const data = await this.$axios.$post('vapor/signed-storage-url', {
            'bucket': options.bucket || '',
            'content_type': options.contentType || file.type,
            'expires': options.expires || '',
            'visibility': options.visibility || ''
        });

        let headers = data.headers;

        if ('Host' in headers) {
            delete headers.Host;
        }

        if (typeof onProgress === 'undefined' || onProgress === null) {
            onProgress = () => { };
        }

        try {
            const s3Response = await Http.put(data.url, file, {
                headers: headers,
                onUploadProgress: (progressEvent) => {
                    onProgress((progressEvent.loaded / progressEvent.total) * 100);
                }
            })
        } catch (error) {
            console.log(error)
        }

        data.extension = file.name.split('.').pop()

        return this.$axios.$post(endpoint, {
            uuid: data.uuid,
            key: data.key,
            bucket: data.bucket,
            name: file.name,
            content_type: file.type,
        })
    }
}