var render = function render(){var _vm=this,_c=_vm._self._c;return _c('o-form-group',{attrs:{"id":_vm.$attrs.id,"label":_vm.label,"required":_vm.required,"help-text":_vm.helpText},scopedSlots:_vm._u([{key:"default",fn:function({ id }){return [_c('div',{staticClass:"input-group",class:{
            'input-group-sm': _vm.size === 'sm',
            'input-group-lg': _vm.size === 'lg',
        }},[(_vm.prepend)?_c('div',{staticClass:"input-group-prepend"},[(Array.isArray(_vm.prepend))?_c('div',{staticClass:"input-group-text"},[_c('o-icon',{attrs:{"icon":_vm.prepend}})],1):_c('div',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.prepend))])]):_vm._e(),_vm._v(" "),_c('input',{ref:"input",staticClass:"form-control",class:{
                    'bg-ultralight': _vm.disabled,
                    'bg-darkish border-dark text-white': _vm.active,
                    'is-invalid': _vm.invalid,
                    'form-control-sm': _vm.size === 'sm',
                    'form-control-lg': _vm.size === 'lg',
                    'form-control-frameless': _vm.frameless,
                },attrs:{"type":_vm.showPassword ? 'text' : _vm.type,"name":_vm.name,"id":`form-input-${id}`,"aria-describedby":`form-input-${_vm.label}-help`,"placeholder":_vm.placeholder,"autocomplete":_vm.autocomplete ? typeof _vm.autocomplete === 'boolean' ? 'on' : _vm.autocomplete : 'off',"autofocus":_vm.autofocus,"required":_vm.required,"disabled":_vm.disabled,"step":_vm.step,"min":_vm.min,"max":_vm.max,"inputmode":_vm.inputmode || (_vm.type === 'number' ? 'decimal' : null)},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":function($event){return _vm.$emit('blur', $event.target.value)}}}),_vm._v(" "),(_vm.append)?_c('div',{staticClass:"input-group-append"},[(Array.isArray(_vm.append))?_c('div',{staticClass:"input-group-text"},[_c('o-icon',{attrs:{"icon":_vm.append}})],1):_c('div',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.append))])]):_vm._e(),_vm._v(" "),(_vm.type === 'password')?_c('div',{staticClass:"input-group-append"},[_c('a',{staticClass:"input-group-text btn btn-link",class:{ 'bg-darkish text-white': _vm.showPassword },attrs:{"href":"javascript:;","title":"Show password"},on:{"click":function($event){return _vm.togglePasswordVisiblity()}}},[_c('o-icon',{attrs:{"icon":['far', _vm.showPassword ? 'eye-slash' : 'eye'],"fixed-width":""}})],1)]):_vm._e()]),_vm._v(" "),(_vm.validationMessage)?_c('div',{staticClass:"text-danger mt-2",staticStyle:{"font-size":".9rem"}},[_vm._v(_vm._s(_vm.validationMessage))]):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }