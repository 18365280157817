<template functional>
<router-link v-if="props.to"
             class="dropdown-item d-flex align-items-center"
             :class="[
            props.active ? 'active' : null,
            props.disabled ? 'disabled' : null,
            data.class,
            data.staticClass
        ]"
             :target="props.target"
             :to="props.to">
	<o-icon v-if="props.icon" :icon="['far', props.icon]" fixed-width class="mr-2 ml-n1"></o-icon>
	<slot></slot>
	<div v-if="props.kbd" class="ml-auto">
		<kbd class="shortcut ml-3">{{ props.kbd }}</kbd>
	</div>
</router-link>
<a v-else
   :href="props.href || 'javascript:;'"
   :target="props.href ? '_blank' : null"
   class="dropdown-item d-flex align-items-center"
   @click="listeners['click'] ? listeners['click']($event) : null"
   :class="[
            props.active ? 'active' : null,
            props.disabled ? 'disabled' : null,
            data.class,
            data.staticClass
        ]">
	<o-icon v-if="props.icon" :icon="[props.iconStyle || 'far', props.icon]" fixed-width class="mr-2 ml-n1"></o-icon>
	<slot></slot>
	<div v-if="props.kbd" class="ml-auto">
		<kbd class="shortcut ml-3">{{ props.kbd }}</kbd>
	</div>
</a>
</template>
<script>
export default {
	props: {
		to: {
			type: [String, Object],
			default: null
		},
		href: {
			type: String,
			default: null
		},
		target: {
			type: String,
			default: null
		},
		icon: {
			type: String,
			default: null,
		},
		iconStyle: {
			type: String,
			default: null,
		},
		active: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		kbd: {
			type: String,
			default: null,
		},
	}
}
</script>
