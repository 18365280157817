export default ({ app }) => {
    // Only run on client-side and only in production mode
    if (process.client) {
        window.onNuxtReady(() => {
            const noscript = document.createElement('noscript');
            const iframe = document.createElement('iframe');

            iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-WKLK4RGH";
            iframe.height = "0";
            iframe.width = "0";
            iframe.style.display = "none";
            iframe.style.visibility = "hidden";

            noscript.appendChild(iframe);
            document.body.insertBefore(noscript, document.body.firstChild);
        });
    }
};
