<template>
    <div class="form-group">
        <label v-if="label" :for="`form-input-${elementId}`">{{ label }} <span v-if="required" class="text-danger">*</span></label>
        <slot :id="elementId" />
        <div v-if="helpText" 
            :id="`form-input-${label}-help`" 
            class="form-text text-muted py-1">
            {{ Array.isArray(helpText) ? helpText.join(', ') : helpText }}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        id: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        helpText: {
            type: [String, Array],
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        elementId() {
            return this.id || this.label || parseInt(Math.random() * 1000)
        }
    }
}
</script>