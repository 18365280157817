<template>
    <div class="page">
        <div class="d-flex align-items-md-center">
            <nuxt />
            <toast-container-component />
        </div>
    </div>
</template>
<script>
import ToastContainerComponent from '~/components/toast-container.vue'
export default {
    middleware: [
        'outdatedBrowser',
    ],

    components: {
        ToastContainerComponent
    }
}
</script>