export default function ({ app, $axios, store, redirect, error: appError }) {
    /**
     * Add Access Token to the Authorization header
     * if a user is logged in and it's an API call to 
     * and internal Endpoint
     */
    $axios.onRequest(config => {
        if (store.getters['user/loggedIn'] && !config.url.includes('http')) {
            config.headers.common['Authorization'] = `Bearer ${store.state.user.token}`
        }
        if ( store.state.locale ) {
            config.headers.common['Accept-Language'] = store.state.locale
        }
        return config
    })

    /**
     * Display various error messages depending
     * on the error's HTTP response code
     */
    $axios.onError(error => {
        const code = parseInt(error.response && error.response.status)
        const errors = error.response ? error.response.data.errors : []

        if (!store.getters['user/loggedIn']) {
            return
        }

        // Validation error
        if (code === 422 && store.getters['user/loggedIn']) {
            const validationMessages = Object.keys(errors).map(key => errors[key].join(' / '))

            store.dispatch('toast/push', {
                title: error.response.data.message,
                type: 'danger',
                message: validationMessages
            })

            return
        }

        // Authorization error: Forbidden
        if (code === 403) {
            store.dispatch('toast/push', {
                title: app.i18n.t('error.403.title'),
                message: app.i18n.t('error.403.message'),
                type: 'danger'
            })

            return
        }

        // Authorization error: Forbidden
        if (code === 404) {
            store.dispatch('toast/push', {
                title: app.i18n.t('error.404.title'),
                message: app.i18n.t('error.404.message'),
                type: 'danger'
            })

            return
        }

        // Payment required (No Active Subscription)
        if (code === 402) {
            store.dispatch('toast/push', {
                title: error.response.data.message,
                type: 'warning'
            })
            return
        }

        // Throttling error: Too many requests
        if (code === 429) {
            store.dispatch('toast/push', {
                title: app.i18n.t('error.429.title'),
                message: app.i18n.t('error.429.message'),
                type: 'danger'
            })
            return
        }

        // Server error
        if (code >= 500) {
            store.dispatch('toast/push', {
                title: app.i18n.t('error.500.title'),
                message: error.response.data.message || app.i18n.t('error.500.message'),
                type: 'danger'
            })

            // Sentry.showReportDialog({
            //     user: {
            //         name: store.state.user.fullName,
            //         email: store.state.user.email,
            //     },
            //     errorGeneric: error.response.data.message,
            // });

            return
        }

        // Offline
        if (!window.navigator.onLine) {
            console.error('Network offline, could not make API call')

            return
        }

        // appError({
        //     statusCode: code,
        //     message: error.response && error.response.data
        //         ? error.response.data.message
        //         : app.i18n.t(`error.${code}.message`)
        // })
    })
}