<template>
	<o-modal :title="$t('modal.quiz.title')" :confirmText="$t('general.got-it')" size="lg">
        <div class="p-4">
            <p>{{ $org ? $t('modal.quiz.text-organization') : $t('modal.quiz.text-applicant') }}</p>
            <img :src="`/test_${$org ? 'arbeitgeber' : 'arbeitnehmer'}.gif`" class="img-fluid">
        </div>
	</o-modal>
</template>
<script>
import OModal from "~/components/ui/layout/Modal.vue";

export default {
	components: {
		OModal
    },
    props: {
        '$org': {
            default: null
        },
        '$t': {
            type: Function
        },
    }
};
</script>
