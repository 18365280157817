import Vue from 'vue'

export const state = () => ({
	applications: [],
	pagination: {},
	orderBy: 'match'
})

export const actions = {
	async fetch({ commit, state }, params = { page: 1 }) {
		const queryString = Object.entries(params)
			.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
			.join('&');

		await this.$axios.$get(`/application?${queryString}`).then((response) => {
			commit('set', { data: response.data, meta: response.meta });
		});
	},

    async needApplications({ dispatch, state }) {
        if (state.applications.length <= 0) {
            await dispatch('fetch')
        }

        return state.applications
    },

    async update({ dispatch, commit }, data) {
		console.log('data-id', data.id)
		return this.$axios.patch(`/application/${data.id}`, data)
			.then((res) => {
				commit('update', res.data);
				dispatch('toast/push', {
					title: this.app.i18n.t('crud.saved'),
					message: null,
					type: 'success'
				}, {'root': true});
			})
			.catch((error) => {
				console.error("Error updating application:", error.response);
			});
	},

    async delete({ commit }, id) {
        this.$axios.delete(`/application/${id}`).then(() => {
            commit('delete', id)
        })
    },

    store({ dispatch, commit }, { applicantProfileId, jobOfferId, status = null }) {
        const data = {
            applicant_profile_id: applicantProfileId,
            job_offer_id: jobOfferId,
            status: status
        }

        return this.$axios
            .$post(`/application`, data)
            .then((data) => {
                commit('add', data)

                if (data.status != 'denied') {
                    dispatch('toast/push', {
                        title: this.app.i18n.t('crud.application-created'),
                        message: this.app.i18n.t('crud.application-created-message'),
                        type: 'success'
                    }, {'root': true})
                }

                return data
            })
    }
}

export const getters = {}

export const mutations = {
    add: (state, data) => state.applications.push(data),
    update: (state, data) => state.applications[
        state.applications.indexOf(
            state.applications.find(i => i.id == data.id)
        )
    ].status = data.status,

    delete: (state, id) => Vue.delete(
        state.applications, state.applications.indexOf(
            state.applications.find(i => i.id == id)
        )
    ),

	set: (state, {data, meta}) => {
		state.applications = data;
		state.pagination = meta;
	},
    sort: (state, newOrder = 'match') => {
        if (state.orderBy != newOrder) {
            state.orderBy = newOrder
        }

        if (state.orderBy == 'match') {
            state.applications = state.applications.sort((a, b) => {
                return a.match.match < b.match.match ? 1 : -1
            })
        }

        if (state.orderBy == 'date') {
            state.applications = state.applications.sort((a, b) => {
                return new Date(b.updated_at) - new Date(a.updated_at);
            })
        }
    }
}
