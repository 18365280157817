<template>
    <div>
        <o-button 
            type="primary" 
            icon="check" 
            @click="accept()">
            {{ $t('crud.accept') }}
        </o-button>

        <o-button 
            type="light" 
            @click="decline()">
            {{ $t('crud.decline') }}
        </o-button>
    </div>
</template>
<script>
export default {
    props: {
        notification: {
            type: Object,
            required: true
        }
    },

    methods: {
        accept() {
            this.$emit('dismiss')
            this.$store.dispatch('organization/acceptInvitation', this.notification.data.invitation)
        },

        decline() {
            this.$emit('dismiss')
            this.$store.dispatch('organization/declineInvitation', this.notification.data.invitation)
        }
    }
}
</script>