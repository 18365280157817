<template functional>
    <li class="nav-item">
        <nuxt-link v-if="props.link" class="nav-link" active-class="active" :exact="exact" :to="props.link" :replace="replace">
            <o-icon v-if="props.icon" :icon="[props.iconPrefix, props.icon]" fixed-width :class="{ 'mr-1': slots().default }"></o-icon>
            <slot />
        </nuxt-link>
        <a v-else href="javascript:;" class="nav-link" :class="{ 'active': props.active }" @click="listeners['click'] ? listeners['click']($event) : null">
            <o-icon v-if="props.icon" :icon="[props.iconPrefix, props.icon]" fixed-width :class="{ 'mr-1': slots().default }"></o-icon>
            <slot v-else />
        </a>
    </li>
</template>
<script>
export default {
    props: {
        link: {
            type: [String, Object],
            default: null
        },
        icon: {
            type: String,
            default: null,
        },
        iconPrefix: {
            type: String,
            default: 'far',
        },
        active: {
            type: Boolean,
            default: false,
        },
        exact: {
            type: Boolean,
            default: true,
        },
        replace: {
            type: Boolean,
            default: true
        }
    }
}
</script>