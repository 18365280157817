<template functional>
	<div :class="{'position-sticky top-0': props.sticky, 'pb-3 pt-4 bg-white w-100': true}" style="z-index: 10">
		<div class="d-md-flex align-items-end align-items-md-center justify-content-between" :class="{ 'text-center': props.center }">
			<div class="flex flex-1">
	            <div v-if="props.image" class="d-flex align-items-center mr-3" style="width: 3.2rem; height: 3.2rem;">
	                <img :src="props.image" class="img-fluid rounded-circle">
	            </div>
	            <div class="flex align-items-center">
	                <div v-if="slots().left" class="mr-3">
	                    <slot name="left" />
	                </div>
	                <div>
                        <div class="prehead" v-if="props.prehead">
							<nuxt-link v-if="props.preheadUrl" :to="props.preheadUrl">
	                            {{ props.prehead }}
							</nuxt-link>
	                        <span v-else>
		                        {{ props.prehead }}
	                        </span>
                        </div>
	                    <h1 v-if="props.title" class="mt-0">{{ props.title }} <small v-if="props.postfix">{{ props.postfix }}</small></h1>
	                    <div class="row">
	                        <div class="col-12">
	                            <slot name="subtitle" />
	                        </div>
	                    </div>
	                </div>
	            </div>
			</div>
			<div class="d-md-block d-none align-self-start pt-2 ml-auto">
				<slot name="actions" />
			</div>
            <div>
				<div class="d-md-none d-block">
					<slot name="actions" />
				</div>
                <slot />
                <nuxt-link v-if="props.buttonUrl && props.buttonText" :to="props.buttonUrl">
                    <button class="btn btn-primary">{{ props.buttonText }}</button>
                </nuxt-link>
            </div>
        </div>
<!--        <div :class="slots().left ? 'd-block d-md-none' : 'd-none'">-->
<!--            <hr class="mb-2">-->
<!--            <div class="prehead" v-if="props.prehead">{{ props.prehead }}</div>-->
<!--            <h1 v-if="props.title" class="m-0">{{ props.title }} <small v-if="props.postfix">{{ props.postfix }}</small></h1>-->
<!--            <slot name="subtitle" />-->
<!--        </div>-->
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },
        buttonUrl: {
            type: String,
            default: null
        },
        buttonText: {
            type: String,
            default: null
        },
        postfix: {
            type: [String, Number],
            default: null
        },
        prehead: {
            type: String,
            default: null
        },
	    preheadUrl: {
		    type: String,
		    default: null
		},
        center: {
            type: Boolean,
            default: false
        },
        image: {
            type: String,
            default: null
        },
	    sticky: {
			type: Boolean,
		    default: false
	    }
    }
}
</script>
