<template>
<o-header :title="$t('app.title')" :sidebar-open="$store.state.sidebarOpen">
	<o-header-group brand>
		<router-link :to="$user ? '/dashboard' : '/'">
			<img src="/logo_small.png" alt="Apropos Jobs Logo">
		</router-link>
		<div v-if="$user" class="header-links d-none d-lg-flex gap-x-6 ml-5">
			<router-link to="/dashboard" class="flex items-center gap-x-1.5">
				<o-icon :icon="['far', 'home']" size="lg" class="pb-0.5"></o-icon>
				{{ $t('link.dashboard') }}
			</router-link>
			<router-link to="/talent-pool" class="flex items-center gap-x-1.5">
				<o-icon :icon="['far', 'heart']" size="lg"></o-icon>
				{{ $org ? $t('link.talent-pool') : $t('general.matches') }}
			</router-link>
			<router-link v-if="$user.applicant_profile" to="/applicant-profile"
						 class="flex items-center gap-x-1.5">
				<o-icon :icon="['far', 'user']" size="lg" class="pb-0.5"></o-icon>
				{{ $t('link.applicant-profile') }}
			</router-link>
		</div>
	</o-header-group>
	<o-header-group>
		<div v-if="$user" class="flex items-center gap-x-6">
			<router-link :to="$org?'/message/pending':'/message/invites'"
			             class="text-white d-none d-md-flex items-center gap-x-1.5">
				<o-icon :icon="['far', 'envelope']" size="lg"></o-icon>
				{{ $t('general.applications') }}
			</router-link>

			<OrganizationDropdown v-if="$org"/>

			<o-dropdown @dropdownIsOpen="profileDropdown">
				<a href="javascript:void(0)" slot="toggle">
					<div class="text-white py-[6px] flex items-center">
						<div v-if="$user.avatar"
						     :style="{ backgroundImage: `url(${$user.avatar.url})`, backgroundClip: 'padding-box', width: '1.5rem', height: '1.5rem' }"
						     class="rounded-circle bg-cover bg-light">
							&nbsp;
						</div>
						<div v-else>
							<o-icon class="text-white" :icon="['fas', 'user-circle']" size="lg"></o-icon>
							<o-icon :icon="['far', 'chevron-down']" class="transition-all text-xs ml-0.5"
									:class="profileDropdownOpen ? 'rotate-180' : ''"/>
						</div>
<!--						<span class="ml-2">-->
<!--                            {{ $t('link.profile') }}-->
<!--                        </span>-->
					</div>
				</a>

				<o-dropdown-header>
					{{ $user.fullName }}
				</o-dropdown-header>
				<o-dropdown-item to="/user/profile" icon="user-circle">
					{{ $t('user.profile') }}
				</o-dropdown-item>
				<o-dropdown-item to="/auth/logout" icon="sign-in">
					{{ $t('auth.sign-out') }}
				</o-dropdown-item>
			</o-dropdown>


<!--            <o-button @click="$store.dispatch('notification/toggleSlideout')" v-tooltip:bottom="$t('general.notifications')">-->
<!--                <span class="icon-wrapper">-->
<!--                    <o-icon class="text-white" :icon="['fas', 'bell']" size="lg"></o-icon>-->
<!--                    <span v-if="$store.state.notification.unread.length > 0" class="icon-layer">{{ $store.state.notification.unread.length }}</span>-->
<!--                </span>-->
<!--            </o-button>-->

			<!--                <language-switcher />-->

			<div class="d-lg-none">
				<o-button @click.stop="$store.commit('toggleSidebar')">
					<o-icon class="text-white" :icon="['far', $store.state.sidebarOpen ? 'times' : 'bars']" size="lg"></o-icon>
				</o-button>
			</div>
		</div>
		<div v-else>
			<router-link class="text-white" to="/auth/login">{{ $t('auth.log-in') }}</router-link>
		</div>
	</o-header-group>
</o-header>
</template>
<script>
import {MainHeaderLinks} from '~/components/links'
import OrganizationDropdown from '~/components/organizationDropdown'
import LanguageSwitcher from '~/components/language-switcher.vue'

export default {
	components: {
		OrganizationDropdown,
		LanguageSwitcher,
	},
	data() {
		return {
			links: null,
			profileDropdownOpen: false,
		}
	},
	methods: {
		showOnboarding() {
			this.$store.dispatch('showOnboarding')
			this.$router.push('/dashboard')
		},
		profileDropdown(value) {
			this.profileDropdownOpen = value;
		}
		// changeLocaleTo(locale) {
		//     if ( this.$store.state.locale === locale ) return;
		//     if ( !this.$store.state.locales.includes(locale) ) return;

		//     this.$store.dispatch('changeLocale', locale);
		// }
	},

	created() {
		this.links = MainHeaderLinks
	},
}
</script>
