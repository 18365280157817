<template functional>
    <div class="alert d-flex" :class="[`alert-${props.type}`, data.class, data.staticClass]" role="alert">
        <o-icon v-if="props.icon" :icon="['far', props.icon]" class="d-block mt-1 mr-3" />
        <div>
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: 'light'
        },
        icon: {
            type: String,
            default: null
        }
    }
}
</script>