import Vue from 'vue'

export const state = () => ({
	jobOffers: []
})

export const getters = {
	jobOffers(state) {
		return state.jobOffers
	},
}

export const actions = {
	async needJobOffers({dispatch, state}) {
		if (!state.jobOffers || state.jobOffers.length == 0) {
			await dispatch('fetch')
		}

		return state.jobOffers
	},

	async fetch({commit}) {
		await this.$axios.$get(`/job-offer`).then(data => {
			commit('setJobOffers', data)
		})
	},

	async fetchById({commit}, id) {
		try {
			const response = await this.$axios.$get(`/job-offer/${id}`);
			commit('setSingleJobOffer', response);
			return response;
		} catch (error) {
			console.error('Error fetching job offer by ID:', error);
		}
	},

	async update({dispatch, commit}, jobOffer) {
		let languages

		if (jobOffer.languages) {
			languages = jobOffer.languages.map(language => {
				return {
					id: language.id,
					attributes: {
						niveau: language.niveau
					}
				}
			})
		}

		let educationTypes = {}

		if (jobOffer.educationTypes) {
			educationTypes = jobOffer.educationTypes.map(educationType => {
				return {
					id: educationType.id,
					attributes: {
						name: educationType.name,
						end_year: educationType.end_year
					}
				}
			})
		}

		let data = {
			'id': jobOffer.id,
			'name': jobOffer.name,
			'active': jobOffer.active,
			'description': jobOffer.description,
			'salary': jobOffer.salary,
			'url': jobOffer.url,
			'min_pensum': jobOffer.min_pensum,
			'max_pensum': jobOffer.max_pensum,
			'education_types': educationTypes,
			'requirements': jobOffer.requirements,
			'remote_work_available': jobOffer.remote_work_available,
			// 'available_from': jobOffer.available_from,
			// 'available_until': jobOffer.available_until,
			'start_date': jobOffer.start_date,
			'languages': languages,
			'province_id': jobOffer.province_id,
			'employment_type_id': jobOffer.employment_type_id,
			'industry_id': jobOffer.industry_id,
			'company_size_id': jobOffer.company_size_id,
			'job_position_id': jobOffer.job_position_id,
			'occupational_field_id': jobOffer.occupational_field_id,
			'personality': jobOffer.personality
		}

		await this.$axios
			.$patch(`/job-offer/${data.id}`, data)
			.then(data => {
				dispatch('toast/push', {
						title: data.name || this.app.i18n.t('crud.updated'),
						message: this.app.i18n.t('crud.updated-text'),
						type: 'success'
					},
					{root: true}
				)
				commit('setJobOfferById', data)
			})
			.catch(() => {
			})
	},

	async store({commit}, item) {
		return await this.$axios.$post(`/job-offer`, item).then(data => {
			commit('addOffer', data)
			return data
		})
	},

	async delete({commit, dispatch}, id) {
		try {
			const response = await this.$axios.$delete(`/job-offer/${id}`);
			commit('removeOffer', response.data);
			dispatch('toast/push', {
				title: this.app.i18n.t('crud.deleted'),
				message: this.app.i18n.t('crud.successfully-deleted'),
				type: 'success'
			}, { root: true });
		} catch (error) {
			console.error('Error deleting job offer:', error);
		}
	},

	async duplicate({ commit, dispatch }, id) {
		try {
			const response = await this.$axios.$post(`/job-offer/${id}/duplicate`);
			commit('addOffer', response.data);
			dispatch('toast/push', {
				title: this.app.i18n.t('crud.duplicated'),
				message: this.app.i18n.t('crud.duplicated-text'),
				type: 'success'
			}, { root: true });
		} catch (error) {
			console.error('Error duplicating job offer:', error);
		}
	},
}

export const mutations = {
	setJobOfferById(state, jobOffer) {
		const index = state.jobOffers.findIndex(j => j.id === jobOffer.id);
		if (index !== -1) {
			Vue.set(state.jobOffers, index, jobOffer);
		}
	},
	setSingleJobOffer(state, offer) {
		state.currentJobOffer = offer;
	},
	setJobOffers: (state, offers) => (state.jobOffers = offers),
	addOffer: (state, offer) => state.jobOffers.push(offer),
	removeOffer: (state, id) => state.jobOffers = state.jobOffers.filter(j => j.id !== id)
}
