import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2756fdd0 = () => interopDefault(import('../pages/applicant-profile/index.vue' /* webpackChunkName: "pages/applicant-profile/index" */))
const _e13eb62a = () => interopDefault(import('../pages/browse.vue' /* webpackChunkName: "pages/browse" */))
const _73caeae4 = () => interopDefault(import('../pages/browser-outdated.vue' /* webpackChunkName: "pages/browser-outdated" */))
const _155af1c3 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _ed771c84 = () => interopDefault(import('../pages/job-offer/index.vue' /* webpackChunkName: "pages/job-offer/index" */))
const _5a79cf76 = () => interopDefault(import('../pages/message.vue' /* webpackChunkName: "pages/message" */))
const _7b8e6579 = () => interopDefault(import('../pages/message/index.vue' /* webpackChunkName: "pages/message/index" */))
const _3bec5560 = () => interopDefault(import('../pages/message/accepted.vue' /* webpackChunkName: "pages/message/accepted" */))
const _818ff0f8 = () => interopDefault(import('../pages/message/denied.vue' /* webpackChunkName: "pages/message/denied" */))
const _793d04d2 = () => interopDefault(import('../pages/message/hired.vue' /* webpackChunkName: "pages/message/hired" */))
const _46d001b0 = () => interopDefault(import('../pages/message/interview.vue' /* webpackChunkName: "pages/message/interview" */))
const _67d6cb71 = () => interopDefault(import('../pages/message/invites.vue' /* webpackChunkName: "pages/message/invites" */))
const _02c753fe = () => interopDefault(import('../pages/message/pending.vue' /* webpackChunkName: "pages/message/pending" */))
const _3376f454 = () => interopDefault(import('../pages/organization.vue' /* webpackChunkName: "pages/organization" */))
const _4787bcea = () => interopDefault(import('../pages/organization/pricing.vue' /* webpackChunkName: "pages/organization/pricing" */))
const _ec173c24 = () => interopDefault(import('../pages/organization/profile.vue' /* webpackChunkName: "pages/organization/profile" */))
const _3d0c95d6 = () => interopDefault(import('../pages/organization/user.vue' /* webpackChunkName: "pages/organization/user" */))
const _34207445 = () => interopDefault(import('../pages/organization-create.vue' /* webpackChunkName: "pages/organization-create" */))
const _9bd92568 = () => interopDefault(import('../pages/talent-pool.vue' /* webpackChunkName: "pages/talent-pool" */))
const _e369755e = () => interopDefault(import('../pages/welcome.vue' /* webpackChunkName: "pages/welcome" */))
const _7b013c3f = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _b17aebba = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _e3c07a80 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _12cb904e = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _f5834de2 = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _7203de80 = () => interopDefault(import('../pages/job-offer/create.vue' /* webpackChunkName: "pages/job-offer/create" */))
const _467954f4 = () => interopDefault(import('../pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _357a76fe = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4c46e743 = () => interopDefault(import('../pages/job-offer/apply/_organization/_jobTitleId.vue' /* webpackChunkName: "pages/job-offer/apply/_organization/_jobTitleId" */))
const _08469e8a = () => interopDefault(import('../pages/applicant-profile/_id/index.vue' /* webpackChunkName: "pages/applicant-profile/_id/index" */))
const _095009ed = () => interopDefault(import('../pages/application/_id/index.vue' /* webpackChunkName: "pages/application/_id/index" */))
const _360e219e = () => interopDefault(import('../pages/applicant-profile/_id/edit.vue' /* webpackChunkName: "pages/applicant-profile/_id/edit" */))
const _70278cf4 = () => interopDefault(import('../pages/applicant-profile/_id/edit/index.vue' /* webpackChunkName: "pages/applicant-profile/_id/edit/index" */))
const _6577dbad = () => interopDefault(import('../pages/applicant-profile/_id/edit/criteria.vue' /* webpackChunkName: "pages/applicant-profile/_id/edit/criteria" */))
const _16a41bca = () => interopDefault(import('../pages/applicant-profile/_id/edit/education.vue' /* webpackChunkName: "pages/applicant-profile/_id/edit/education" */))
const _5912e2b8 = () => interopDefault(import('../pages/applicant-profile/_id/edit/experience.vue' /* webpackChunkName: "pages/applicant-profile/_id/edit/experience" */))
const _27baf78a = () => interopDefault(import('../pages/applicant-profile/_id/edit/general.vue' /* webpackChunkName: "pages/applicant-profile/_id/edit/general" */))
const _add5ca86 = () => interopDefault(import('../pages/applicant-profile/_id/edit/languages.vue' /* webpackChunkName: "pages/applicant-profile/_id/edit/languages" */))
const _6be97110 = () => interopDefault(import('../pages/applicant-profile/_id/edit/personality.vue' /* webpackChunkName: "pages/applicant-profile/_id/edit/personality" */))
const _5a9c9f7f = () => interopDefault(import('../pages/applicant-profile/_id/quiz/index.vue' /* webpackChunkName: "pages/applicant-profile/_id/quiz/index" */))
const _ee421634 = () => interopDefault(import('../pages/applicant-profile/_id/setup.vue' /* webpackChunkName: "pages/applicant-profile/_id/setup" */))
const _1504922e = () => interopDefault(import('../pages/applicant-profile/_id/setup/index.vue' /* webpackChunkName: "pages/applicant-profile/_id/setup/index" */))
const _29171cd0 = () => interopDefault(import('../pages/applicant-profile/_id/setup/criteria.vue' /* webpackChunkName: "pages/applicant-profile/_id/setup/criteria" */))
const _503a433f = () => interopDefault(import('../pages/applicant-profile/_id/setup/education.vue' /* webpackChunkName: "pages/applicant-profile/_id/setup/education" */))
const _5241a9e3 = () => interopDefault(import('../pages/applicant-profile/_id/setup/experience.vue' /* webpackChunkName: "pages/applicant-profile/_id/setup/experience" */))
const _9fa30f82 = () => interopDefault(import('../pages/applicant-profile/_id/setup/general.vue' /* webpackChunkName: "pages/applicant-profile/_id/setup/general" */))
const _3aa97b9c = () => interopDefault(import('../pages/applicant-profile/_id/setup/languages.vue' /* webpackChunkName: "pages/applicant-profile/_id/setup/languages" */))
const _56c266fa = () => interopDefault(import('../pages/job-offer/_id/edit.vue' /* webpackChunkName: "pages/job-offer/_id/edit" */))
const _02f2a4c6 = () => interopDefault(import('../pages/job-offer/_id/edit/index.vue' /* webpackChunkName: "pages/job-offer/_id/edit/index" */))
const _c8ca0ac8 = () => interopDefault(import('../pages/job-offer/_id/edit/education.vue' /* webpackChunkName: "pages/job-offer/_id/edit/education" */))
const _341f62dc = () => interopDefault(import('../pages/job-offer/_id/edit/general.vue' /* webpackChunkName: "pages/job-offer/_id/edit/general" */))
const _2e0bf98f = () => interopDefault(import('../pages/job-offer/_id/edit/languages.vue' /* webpackChunkName: "pages/job-offer/_id/edit/languages" */))
const _e2b8393c = () => interopDefault(import('../pages/job-offer/_id/edit/personality.vue' /* webpackChunkName: "pages/job-offer/_id/edit/personality" */))
const _a59e1568 = () => interopDefault(import('../pages/job-offer/_id/edit/requirements.vue' /* webpackChunkName: "pages/job-offer/_id/edit/requirements" */))
const _2530915e = () => interopDefault(import('../pages/job-offer/_id/quiz/index.vue' /* webpackChunkName: "pages/job-offer/_id/quiz/index" */))
const _829effce = () => interopDefault(import('../pages/applicant-profile/_id/quiz/intro.vue' /* webpackChunkName: "pages/applicant-profile/_id/quiz/intro" */))
const _1448e13a = () => interopDefault(import('../pages/applicant-profile/_id/quiz/results.vue' /* webpackChunkName: "pages/applicant-profile/_id/quiz/results" */))
const _42744f57 = () => interopDefault(import('../pages/job-offer/_id/quiz/manual.vue' /* webpackChunkName: "pages/job-offer/_id/quiz/manual" */))
const _023ffab5 = () => interopDefault(import('../pages/job-offer/_id/quiz/results.vue' /* webpackChunkName: "pages/job-offer/_id/quiz/results" */))
const _59701063 = () => interopDefault(import('../pages/job-offer/_id/quiz/test.vue' /* webpackChunkName: "pages/job-offer/_id/quiz/test" */))
const _60efd624 = () => interopDefault(import('../pages/job-offer/_id/_slug/index.vue' /* webpackChunkName: "pages/job-offer/_id/_slug/index" */))
const _38e7b98e = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/applicant-profile",
    component: _2756fdd0,
    name: "applicant-profile"
  }, {
    path: "/browse",
    component: _e13eb62a,
    name: "browse"
  }, {
    path: "/browser-outdated",
    component: _73caeae4,
    name: "browser-outdated"
  }, {
    path: "/dashboard",
    component: _155af1c3,
    name: "dashboard"
  }, {
    path: "/job-offer",
    component: _ed771c84,
    name: "job-offer"
  }, {
    path: "/message",
    component: _5a79cf76,
    children: [{
      path: "",
      component: _7b8e6579,
      name: "message"
    }, {
      path: "accepted",
      component: _3bec5560,
      name: "message-accepted"
    }, {
      path: "denied",
      component: _818ff0f8,
      name: "message-denied"
    }, {
      path: "hired",
      component: _793d04d2,
      name: "message-hired"
    }, {
      path: "interview",
      component: _46d001b0,
      name: "message-interview"
    }, {
      path: "invites",
      component: _67d6cb71,
      name: "message-invites"
    }, {
      path: "pending",
      component: _02c753fe,
      name: "message-pending"
    }]
  }, {
    path: "/organization",
    component: _3376f454,
    name: "organization",
    children: [{
      path: "pricing",
      component: _4787bcea,
      name: "organization-pricing"
    }, {
      path: "profile",
      component: _ec173c24,
      name: "organization-profile"
    }, {
      path: "user",
      component: _3d0c95d6,
      name: "organization-user"
    }]
  }, {
    path: "/organization-create",
    component: _34207445,
    name: "organization-create"
  }, {
    path: "/talent-pool",
    component: _9bd92568,
    name: "talent-pool"
  }, {
    path: "/welcome",
    component: _e369755e,
    name: "welcome"
  }, {
    path: "/auth/forgot-password",
    component: _7b013c3f,
    name: "auth-forgot-password"
  }, {
    path: "/auth/login",
    component: _b17aebba,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _e3c07a80,
    name: "auth-logout"
  }, {
    path: "/auth/register",
    component: _12cb904e,
    name: "auth-register"
  }, {
    path: "/auth/reset-password",
    component: _f5834de2,
    name: "auth-reset-password"
  }, {
    path: "/job-offer/create",
    component: _7203de80,
    name: "job-offer-create"
  }, {
    path: "/user/profile",
    component: _467954f4,
    name: "user-profile"
  }, {
    path: "/",
    component: _357a76fe,
    name: "index"
  }, {
    path: "/job-offer/apply/:organization/:jobTitleId?",
    component: _4c46e743,
    name: "job-offer-apply-organization-jobTitleId"
  }, {
    path: "/applicant-profile/:id",
    component: _08469e8a,
    name: "applicant-profile-id"
  }, {
    path: "/application/:id",
    component: _095009ed,
    name: "application-id"
  }, {
    path: "/applicant-profile/:id?/edit",
    component: _360e219e,
    children: [{
      path: "",
      component: _70278cf4,
      name: "applicant-profile-id-edit"
    }, {
      path: "criteria",
      component: _6577dbad,
      name: "applicant-profile-id-edit-criteria"
    }, {
      path: "education",
      component: _16a41bca,
      name: "applicant-profile-id-edit-education"
    }, {
      path: "experience",
      component: _5912e2b8,
      name: "applicant-profile-id-edit-experience"
    }, {
      path: "general",
      component: _27baf78a,
      name: "applicant-profile-id-edit-general"
    }, {
      path: "languages",
      component: _add5ca86,
      name: "applicant-profile-id-edit-languages"
    }, {
      path: "personality",
      component: _6be97110,
      name: "applicant-profile-id-edit-personality"
    }]
  }, {
    path: "/applicant-profile/:id?/quiz",
    component: _5a9c9f7f,
    name: "applicant-profile-id-quiz"
  }, {
    path: "/applicant-profile/:id?/setup",
    component: _ee421634,
    children: [{
      path: "",
      component: _1504922e,
      name: "applicant-profile-id-setup"
    }, {
      path: "criteria",
      component: _29171cd0,
      name: "applicant-profile-id-setup-criteria"
    }, {
      path: "education",
      component: _503a433f,
      name: "applicant-profile-id-setup-education"
    }, {
      path: "experience",
      component: _5241a9e3,
      name: "applicant-profile-id-setup-experience"
    }, {
      path: "general",
      component: _9fa30f82,
      name: "applicant-profile-id-setup-general"
    }, {
      path: "languages",
      component: _3aa97b9c,
      name: "applicant-profile-id-setup-languages"
    }]
  }, {
    path: "/job-offer/:id?/edit",
    component: _56c266fa,
    children: [{
      path: "",
      component: _02f2a4c6,
      name: "job-offer-id-edit"
    }, {
      path: "education",
      component: _c8ca0ac8,
      name: "job-offer-id-edit-education"
    }, {
      path: "general",
      component: _341f62dc,
      name: "job-offer-id-edit-general"
    }, {
      path: "languages",
      component: _2e0bf98f,
      name: "job-offer-id-edit-languages"
    }, {
      path: "personality",
      component: _e2b8393c,
      name: "job-offer-id-edit-personality"
    }, {
      path: "requirements",
      component: _a59e1568,
      name: "job-offer-id-edit-requirements"
    }]
  }, {
    path: "/job-offer/:id?/quiz",
    component: _2530915e,
    name: "job-offer-id-quiz"
  }, {
    path: "/applicant-profile/:id?/quiz/intro",
    component: _829effce,
    name: "applicant-profile-id-quiz-intro"
  }, {
    path: "/applicant-profile/:id?/quiz/results",
    component: _1448e13a,
    name: "applicant-profile-id-quiz-results"
  }, {
    path: "/job-offer/:id?/quiz/manual",
    component: _42744f57,
    name: "job-offer-id-quiz-manual"
  }, {
    path: "/job-offer/:id?/quiz/results",
    component: _023ffab5,
    name: "job-offer-id-quiz-results"
  }, {
    path: "/job-offer/:id?/quiz/test",
    component: _59701063,
    name: "job-offer-id-quiz-test"
  }, {
    path: "/job-offer/:id?/:slug",
    component: _60efd624,
    name: "job-offer-id-slug"
  }, {
    path: "/:slug",
    component: _38e7b98e,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
