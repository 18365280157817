<template>
    <div class="page">
		<!--<img src="/logo.png" class="page__logo">-->
        <div class="d-flex align-items-md-center" style="min-height: 100vh;">
            <nuxt />
            <toast-container-component />
        </div>
        <footer-component />
    </div>
</template>
<script>
import ToastContainerComponent from '~/components/toast-container.vue'
import FooterComponent from '~/components/footer.vue'
export default {
    middleware: [
        'outdatedBrowser',
    ],

    components: {
        FooterComponent,
        ToastContainerComponent
    }
}
</script>
