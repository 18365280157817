var render = function render(_c,_vm){return _c('div',_vm._g({staticClass:"card",class:[{ 
        'card-dark': _vm.props.dark, 
        'card-light': _vm.props.light, 
        'card-invisible': _vm.props.invisible, 
        'card-action': _vm.props.clickable, 
        'card-stretch': _vm.props.fullHeight,
    }, _vm.data.class, _vm.data.staticClass],style:([_vm.data.style, _vm.data.staticStyle]),attrs:{"tabindex":_vm.props.clickable ? '0' : null}},_vm.listeners),[(_vm.slots().top)?_c('div',[_vm._t("top")],2):_vm._e(),_vm._v(" "),(_vm.props.image)?_c('img',{staticClass:"card-img-top",attrs:{"src":_vm.props.image}}):_vm._e(),_vm._v(" "),(_vm.slots().header)?_c('div',{staticClass:"card-header d-flex align-items-center justify-content-end",class:{ 'card-sticky': _vm.props.stickyHeader }},[_vm._t("header")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-body",class:{ 'pb-0': _vm.props.noPaddingBottom }},[(_vm.props.title)?_c('h2',{staticClass:"card-title d-flex justify-content-between align-items-center",staticStyle:{"word-break":"break-word"}},[_vm._v("\n            "+_vm._s(_vm.props.title)+"\n        ")]):_vm._e(),_vm._v(" "),_vm._t("default")],2),_vm._v(" "),(_vm.slots().footer)?_c('div',{staticClass:"card-footer"},[_vm._t("footer",null,{"profile":_vm.profile})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }