import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default async ({ app, store, $axios }) => {

    const i18n = new VueI18n({
        locale: store.state.locale,
        fallbackLocale: 'de'
    })
    
    await $axios.$get('/interface-string').then(data => {
        Object.keys(data).forEach(locale => {
            i18n.setLocaleMessage(locale, data[locale])
        });
    })
    
    app.i18n = i18n

}