<template>
    <o-sidebar
        :app="$t('app.title')"
        :title="$org ? $org.name : greeting"
        :open="$store.state.sidebarOpen"
        @toggle="$store.commit('toggleSidebar')"
        @click.native.stop
        class="d-lg-none">
        <template #icon>
            <nuxt-link to="/">
                <img src="/logo_small.png" class="img-fluid" alt="Apropos Jobs Logo">
            </nuxt-link>
        </template>

        <div>
            <o-sidebar-group>
                <o-sidebar-item :label="$t('link.dashboard')" link="/dashboard" />
                <o-sidebar-item :label="$org ? $t('link.talent-pool') : $t('general.matches')" link="/talent-pool" />
                <o-sidebar-item :link="$org?'/message/pending':'/message/invites'" :label="$t('general.applications')" />
            </o-sidebar-group>

<!--            <o-sidebar-group v-if="$org" :name="$org.name">-->
<!--                <o-sidebar-item v-for="(link, label) in SubHeaderLinks" :key="label" :label="$t('link.' + label)" :link="link" />-->
<!--            </o-sidebar-group>-->

            <a v-if="$org" href="https://calendly.com/apropos-jobs/beratung" target="_blank" :title="$t('link.get-support-title')" class="get-support-link">
                <o-icon :icon="['far', 'phone']" class="mr-1" />
                {{ $t('link.get-support') }}
            </a>

            <o-sidebar-group v-if="$user && $user.applicant_profile" :name="$user.fullName">
                <o-sidebar-item link="/applicant-profile" :label="$t('general.applicant-profile')" tooltip />
            </o-sidebar-group>
        </div>
    </o-sidebar>
</template>
<script>

import { MainHeaderLinks, SubHeaderLinks } from '~/components/links'

export default {
    data () {
        return {
            MainHeaderLinks: null,
            SubHeaderLinks: null,
            greeting: this.$user?.firstname ? this.$t('user.greeting', { name: this.$user.firstname }) : this.$t('app.welcome')
        }
    },
    created () {
        this.MainHeaderLinks = MainHeaderLinks
        this.SubHeaderLinks = SubHeaderLinks
    }
}
</script>
<style scoped>
.get-support-link {
    font-size: .8em;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 1.5rem 1rem;
    white-space: nowrap;
}
</style>
