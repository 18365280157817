export const state = () => ({
    filterCriteria: null,
})

export const mutations = {
    setFilterCriteria: (state, data) => state.filterCriteria = data
}

export const actions = {
    async fetch ({ commit, state }) {
        await this.$axios.$get('/filter-criteria').then((data) => {
            commit('setFilterCriteria', data)
        })
    },

    async needFilterCriteria({ dispatch, state }) {
        if (!state.filterCriteria) {
            await dispatch('fetch')
        }

        return state.filterCriteria
    }
}