export default ({ filters = {}, page, sort, include = [], append = [], transform = [], perPage }) => {
    let params = {}

    // Pagination
    if (typeof perPage === 'undefined') {
        params['per_page'] = 14
    } else if (perPage) {
        params['per_page'] = perPage
    }

    if (page) {
        params['page'] = page
    }

    // Sorting
    if (sort) {
        params['sort'] = sort
    }

    // Appends
    if (append && append.length > 0) {
        params['append'] = append.join(',')
    }

    // Transforms
    if (transform && transform.length > 0) {
        params['transform'] = transform.join(',')
    }

    // Includes
    if (include && include.length > 0) {
        params['include'] = include.join(',')
    }

    // Filters
    Object.keys(filters).forEach(key => {
        let value = filters[key]

        if (value === true) {
            value = 'true'
        } else if (value === false) {
            value = 'false'
        }

        if (value) {
            params[`filter[${key}]`] = filters[key]
        }
    })

    // Build query string
    return '?' + Object.keys(params).map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    }).join('&')
}