export default async function ({ store, redirect, route, from }) {

    const user = store.getters['user/user']

    if (user && (user.type == 'applicant')) {
        const applicantProfile = await store.dispatch('applicantProfile/needApplicantProfile')
        if (!applicantProfile) {
            return redirect(200, '/welcome')
        }

        if (!applicantProfile.personality) {
            return redirect(200, `/applicant-profile/${applicantProfile.id}/quiz/intro`)
        }
    }

    if (user && (user.type == 'organization_member' && !user.current_organization)) {
        return redirect(200, `/welcome`)
    }
}