import * as Sentry from '@sentry/browser'
import Storage from '~/util/storage'

export const state = () => {
    return {
        user: Storage.get('user'),
        token: Storage.get('auth-access-token'),
    }
}

export const mutations = {
    setToken(state, token) {
        state.token = token
        Storage.set('auth-access-token', token)
        this.$axios.setToken(`Bearer ${token}`)
    },

    removeToken(state) {
        state.token = null
        Storage.remove('auth-access-token')
    },

    setUser(state, user) {
        state.user = user
        Storage.set('user', user)

        if (user) {
            Sentry.setUser({
                id: user.id,
                username: user.fullName,
                email: user.email,
                organization: user.current_organization ? user.current_organization.name : null,
                current_role: user.current_organization ? user.current_organization.role_in_organization : null
            })
        }
    },
}

export const getters = {
    loggedIn(state) {
        return state.token !== null
    },

    user(state) {
        return state.user
    },

    fullName(state) {
        return `${state.user.firstname} ${state.user.lastname}`
    },

    hasPermissionTo: state => permission => {
        return state.user
            && state.user.current_organization
            && state.user.current_organization.permissions.includes(permission)
    },
}

export const actions = {
    async login({ dispatch, commit }, credentials) {
        let access_token = null
        try {
            const data = await this.$axios.$post('login', credentials)
            access_token = data.access_token
        } catch (error) {
            return Promise.reject(error)
        }

        commit('setToken', access_token)

        return dispatch('getUser')
    },

    async register({ dispatch, commit }, data) {
        const { access_token } = await this.$axios.$post('register', data)
        commit('setToken', access_token)

        return dispatch('getUser')
    },

    async logout({ commit }) {
        await this.$axios.$post('logout')
        commit('removeToken')
        commit('setUser', null)
        this.$router.push('/')
    },

    async forgotPassword({ commit }, email) {
        return await this.$axios.$post('forgot-password', { email })
    },

    async resetPassword({ commit }, data) {
        return await this.$axios.$post('reset-password', data)
    },

    getUser({ commit }) {
        return this.$axios.$get('user').then(data => {
            commit('setUser', data)
        }).catch(error => {
            if (error.response && error.response.status === 401) {
                commit('removeToken')
                commit('setUser', null)
                window.location.reload()
            }
        })
    },

    update({ commit }, data) {
        return this.$axios.$patch('user', data).then(data => {
            commit('setUser', data)
            commit('setLocale', data, { root: true })
        })
    },

    changeOrganization({ commit, dispatch }, organization) {
        return this.$axios.$get('user/change-organization/' + organization.id).then(data => {
            commit('setUser', data)
            dispatch('organization/fetch', data.current_organization.id, { root: true })
            dispatch('toast/push', { title: `Zu ${organization.name} gewechselt` }, { root: true })
            window.location.href = '/'
        })
    },

    leaveOrganization({ state, commit, dispatch }, organization) {
        return this.$axios.$get('user/leave-organization/' + organization.id).then(async (data) => {
            let user = state.user
            user.organizations = user.organizations.filter(v => v.id !== organization.id)
            commit('setUser', user)

            window.location.href = '/'
        })
    },

    uploadAvatar({ commit, dispatch }, { file, onProgress = null }) {
        return dispatch('media/upload', { file, endpoint: 'user/avatar', onProgress }, { root: true }).then(data => {
            commit('setUser', data)
        })
    },

    resendEmailVerification({ dispatch, state }) {
        return this.$axios.$post('user/resend-verification')
        .then((response) => {
            if (response === 'Email already verified') throw new Error(this.app.i18n.t('user.email-already-verified'));
            dispatch('toast/push', {
                title: this.app.i18n.t('user.verification-sent'),
                message: this.app.i18n.t('user.verification-sent-text', { email: state.user.email }),
                type: 'success'
            }, { root: true })
        })
        .catch( () => {
            dispatch('toast/push', {
                title: this.app.i18n.t('user.email-already-verified'),
                type: 'info'
            }, { root: true })
        })
    },
}
