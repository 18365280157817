<template>
<div class="app pb-5">
	<div class="brand-line"></div>
	<main class="container">
		<nuxt/>
	</main>
	<div class="container mt-5">
		<div class="col-12 d-flex justify-content-center">
			<a href="https://apropos.jobs" class="border px-3 py-2 rounded-pill" target="_blank">
				<img width="150" src="/logo-font.png" alt="Apropos Jobs Logo">
			</a>
		</div>
	</div>
</div>
</template>
<script>
export default {
	head() {
		return {
			meta: [
				{ hid: 'robots', name: 'robots', content: 'index, follow' }
			]
		}
	}
}
</script>
