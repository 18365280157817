import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import { isInternetExplorer } from '~/util/featureDetect'

export default () => {
    if (process.env.NODE_ENV !== 'production' || isInternetExplorer()) return

    Sentry.init({
        dsn: 'https://df079ef5e1aa4dfe92fb6e53af9b8823@o66833.ingest.sentry.io/6170531',
        integrations: [new Integrations.Vue({
            Vue,
            attachProps: true,
            logErrors: true
        })],
    })
}