<template>
    <transition name="modal">
        <div v-if="show">
            <div class="modal d-block" tabindex="-1" role="dialog" @click="close()">
                <div class="modal-dialog modal-dialog-centered" :class="`modal-${size}`" role="document">
                    <div v-if="frameless" class="modal-content shadow-lg overflow-auto" @click.stop>
                        <slot />
                    </div>
                    <div v-else class="modal-content shadow-lg" @click.stop>
                        <div v-if="title" class="modal-header align-items-center">
                            <h5 class="modal-title">
                                {{ title }}
                            </h5>
                            <o-button icon="times" class="btn-sm text-dark" @click="close()"></o-button>
                        </div>
                        <div class="modal-body" :class="{ 'overflow-auto': !allowOverflow }">
                            <slot></slot>
                        </div>
                        <div v-if="!hideFooter" class="modal-footer">
                            <slot name="footer" :close="close">
                                <o-button v-if="cancelText" @click="close()" type="light">{{ cancelText }}</o-button>
                                <o-button v-if="confirmText"
                                    ref="confirmButton"
                                    @click="close(true)"
                                    :type="type || 'primary'"
                                    :disabled="!allowConfirm">
                                    {{ confirmText }}
                                </o-button>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </div>
    </transition>
</template>
<script>
export default {
    props: {
		title: {
			type: String,
			default: null
		},
		type: {
			type: String,
			default: null
		},
		size: {
			type: String,
			default: 'default'
        },
        confirmText: {
            type: String,
            default: 'OK'
        },
        cancelText: {
            type: String,
            default: null
        },
        allowOverflow: {
            type: Boolean,
            default: false
        },
        allowConfirm: {
            type: Boolean,
            default: true
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        frameless: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            show: false
        }
    },

    mounted() {
        this.show = true
        document.addEventListener('keyup', this.globalKeyDownHandler)

        if (!this.hideFooter && !this.frameless) {
            this.$nextTick(() => this.$refs.confirmButton.focus())
        }
    },

    destroyed() {
        document.removeEventListener('keyup', this.globalKeyDownHandler)
    },

    methods: {
        close(confirm = false) {
            this.show = false

            this.$nextTick(() => {
                if (confirm) {
                    this.$emit('confirm')
                } else {
                    this.$emit('close')
                }
            })
        },

        globalKeyDownHandler(ev) {
            if (ev.keyCode === 27) {
                ev.preventDefault()
                this.close()
            }
        }
    }
};
</script>
