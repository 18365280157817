<template functional>
    <div class="loading-container" :class="[data.class, data.staticClass]"
        :style="props.loading ? [
            data.style, 
            data.staticStyle
        ] : []">
        <slot />
        <transition name="fade">
            <div v-if="props.loading" class="loading-inner">
                <div class="loading-bar">
                    <div v-if="props.message">{{ props.message }}</div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        
        message: {
            type: String,
            default: null
        }
    }
}
</script>