import Vue from 'vue'
import Storage from '~/util/storage'

export default () => {
    Vue.mixin({
        data() {
            return {
                currentTheme: Storage.get('theme')
            }
        },

        methods: {
            $theme(theme = null) {
                if (!theme) {
                    return this.currentTheme
                }

                this.currentTheme = theme
            },
        },

        watch: {
            currentTheme: {
                handler(theme) {
                    if (!theme || theme === 'auto') {
                        Storage.remove('theme')
                        delete window.document.documentElement.dataset.theme
                        return
                    }
    
                    Storage.set('theme', theme)
                    window.document.documentElement.dataset.theme = theme
                },
                immediate: true
            }
        }
    })
}