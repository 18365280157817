import Vue from 'vue'
import Storage from '~/util/storage'
import deepMerge from '~/util/deepmerge'
import queryBuilder from '~/util/crud/queryBuilder'

export default class CRUDStore {
    /**
     * Name of CRUD resource
     */
    resource = null

    /**
     * Create a new factory for CRUD stores
     * @param {string} resource Name of the CRUD resource
     */
    constructor(resource, options = {
        include: []
    }) {
        this.options = options
        this.resource = resource
    }

    /**
     * Base CRUD state
     */
    state() {
        const resource = this.resource

        return {
            items: Storage.get(`${resource}-items`, []),
            links: {
                first: null,
                last: null,
                next: null,
                prev: null
            },
            meta: {
                allowed_filters: [],
                allowed_sorts: [],
                current_page: 0,
                from: 0,
                last_page: 0,
                path: null,
                per_page: 0,
                to: 0,
                total: 0
            },
            query: ''
        }
    }

    /**
     * Base CRUD getters
     */
    getters() {
        return {
            getOne: state => id => {
                return state.items.find(item => item.id == id)
            },
        }
    }

    /**
     * Base CRUD Mutations
     */
    mutations() {
        const resource = this.resource
        const options = this.options

        return {
            SET_ITEMS(state, items) {
                Vue.set(state, 'items', items)

                if (options.saveToLocalStorage) {
                    Storage.set(`${resource}-items`, items)
                }
            },

            SET_LINKS(state, links) {
                Vue.set(state, 'links', links)
            },

            SET_META(state, meta) {
                Vue.set(state, 'meta', meta)
            },

            UPDATE_OR_ADD_ITEM(state, item) {
                const existing = state.items.find(i => i.id == item.id)
                if (existing) {
                    Vue.set(state.items, state.items.indexOf(existing), deepMerge(existing, item))
                } else {
                    state.items.push(item)
                }
            },

            REMOVE_ITEM(state, item) {
                const itemToRemove = state.items.find(i => i.id == item.id)
                Vue.delete(state.items, state.items.indexOf(itemToRemove))
            },

            SET_QUERY(state, query) {
                state.query = query
            }
        }
    }

    /**
     * Base CRUD actions
     */
    actions() {
        const resource = this.resource
        const options = this.options
        const buildQuery = this.buildQuery

        return {
            fetchAll({ commit, state }, query) {
                if (query) {
                    commit('SET_QUERY', buildQuery(query, options))
                }

                return this.$axios.$get(`${resource}${state.query}`).then(({ data, links, meta }) => {
                    commit('SET_ITEMS', data)
                    commit('SET_LINKS', links)
                    commit('SET_META', meta)

                    return { data, links, meta }
                })
            },

            fetchOne({ commit, state }, { id, query }) {
                if (query) {
                    commit('SET_QUERY', buildQuery(query, options))
                }

                return this.$axios.$get(`${resource}/${id}${state.query}`).then(item => {
                    commit('UPDATE_OR_ADD_ITEM', item)

                    return item
                }).catch(error => {
                    return null
                })
            },

            needAll({ dispatch, state }, query) {
                const fetch = dispatch('fetchAll', query)

                // Directly resolve if store already holds items
                // (no assurance of include existance)
                if (state.items.length > 0) {
                    return Promise.resolve(state.items)
                }

                return fetch
            },

            needOne({ dispatch, getters }, { id, query }) {
                const item = getters.getOne(id)
                const fetch = dispatch('fetchOne', { id, query })

                // Directly resolve if item exists in store and
                // already has all requested includes and appends loaded
                if (item
                    && (!query.include || query.include.every(include => typeof item[include] !== 'undefined'))
                    && (!query.append || query.append.every(append => typeof item[append] !== 'undefined'))
                    && (!query.transform || query.transform.every(transform => typeof item[transform] !== 'undefined'))
                ) {
                    return Promise.resolve(item)
                }

                return fetch
            },

            store({ commit, dispatch, state }, item) {
                return this.$axios.$post(resource + state.query, item).then(data => {
                    commit('UPDATE_OR_ADD_ITEM', data)

                    dispatch('toast/push', {
                        title: data.name,
                        message: this.app.i18n.t('crud.created'),
                        type: 'success'
                    }, { root: true })

                    dispatch('fetchAll')

                    return data
                })
            },

            update({ commit, dispatch, state }, item) {
                return this.$axios.$put(`${resource}/${item.id}${state.query}`, item).then(data => {
                    commit('UPDATE_OR_ADD_ITEM', data)

                    // dispatch('toast/push', {
                    //     title: this.app.i18n.t('crud.updated'),
                    //     message: this.app.i18n.t('crud.updated-text'),
                    //     type: 'success'
                    // }, { root: true })

                    return data
                })
            },

            destroy({ commit, dispatch, state }, item) {
                return this.$axios.$delete(`${resource}/${item.id}${state.query}`).then(() => {
                    commit('REMOVE_ITEM', item)

                    dispatch('toast/push', {
                        title: item.name,
                        message: this.app.i18n.t('crud.deleted'),
                        type: 'info'
                    }, { root: true })
                })
            },

            destroyMany({ commit, dispatch, state }, items) {
                return this.$axios.$post(`${resource}/delete-many/${state.query}`, items.map(i => i.id)).then(() => {
                    items.forEach(item => commit('REMOVE_ITEM', item))

                    dispatch('toast/push', {
                        title: this.app.i18n.t('crud.deleted-many'),
                        message: this.app.i18n.t('crud.deleted-many-text', { count: items.length }),
                        type: 'info'
                    }, { root: true })
                })
            },

            uploadFile({ commit, dispatch }, { id, file, collection = '', onProgress = null }) {
                return dispatch('media/upload', {
                    file,
                    endpoint: `${resource}/${id}/upload/${collection}`,
                    onProgress
                }, { root: true })
            },

            sort({ commit, dispatch }, items) {
                commit('SET_ITEMS', items)
                return this.$axios.$post(`${resource}/sort`, items.map(i => i.id)).then(() => {
                    dispatch('fetchAll')
                })
            },

            clone({ commit, dispatch, state }, item) {
                return this.$axios.$post(`${resource}/${item.id}/clone${state.query}`).then(async data => {
                    await dispatch('fetchAll')

                    dispatch('toast/push', {
                        title: item.name || this.app.i18n.t('crud.cloned'),
                        message: this.app.i18n.t('crud.cloned-text'),
                        type: 'success'
                    }, { root: true })

                    return data
                })
            },

            restore({ commit, dispatch }, item) {
                return this.$axios.$patch(`${resource}/${item.id}/restore`).then(async data => {
                    const restoredItem = { ...item }
                    restoredItem.deleted_at = null

                    commit('UPDATE_OR_ADD_ITEM', restoredItem)

                    dispatch('toast/push', {
                        title: item.name || this.app.i18n.t('crud.restored'),
                        message: this.app.i18n.t('crud.restored-text'),
                        type: 'success'
                    }, { root: true })

                    return data
                })
            },

            clearItems({ commit }) {
                commit('SET_ITEMS', [])
            }
        }
    }

    /**
     * Helper function to build an advanced query
     * string for pagination, filtering, sorting and
     * loading relationship data
     * 
     * @param {Object} query Query options
     */
    buildQuery(query, options) {
        if (query) {
            if (options.include) {
                query.include = options.include.concat(query.include)
            }

            return queryBuilder(query)
        }

        return ''
    }
}