import Vue from 'vue'
import { library as IconLibrary } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

// interesting v6 icons not availage yet
// faUserMagnifyingGlass,

import {
	faCopy,
    faCube,
    faFlask,
    faCubes,
    faReply,
    faInbox,
    faEllipsisV,
    faEllipsisH,
    faPlus,
    faMinus,
    faPlusCircle,
    faMinusCircle,
    faPencil,
    faTrashAlt,
    faEye,
    faEyeSlash,
    faSmile,
    faSearch,
    faPen,
    faRocket,
    faArrowRight,
    faLongArrowRight,
    faArrowToRight,
    faArrowLeft,
    faLongArrowLeft,
    faArrowToLeft,
    faArrowUp,
    faArrowDown,
    faStream,
    faClipboardListCheck,
    faCheck,
    faCheckCircle,
    faBars,
    faHome,
    faClock,
    faTruck,
    faUserCircle,
    faUserCog,
    faUserPlus,
    faIndustry,
    faServer,
    faTimes,
    faTachometerAlt,
    faArrows,
    faSortDown,
    faSortUp,
    faSortAmountUp,
    faSortAmountDown,
    faFilter,
    faAngleDown,
    faAngleUp,
    faAngleRight,
    faAngleLeft,
    faListAlt,
    faTag,
    faExclamationTriangle,
    faExclamationCircle,
    faQuestionCircle,
    faUsers,
    faAddressCard,
    faGripVertical,
    faSignIn,
    faSignOut,
    faCog,
    faGenderless,
    faLanguage,
    faPaperPlane,
    faAt,
    faKey,
    faFileInvoiceDollar,
    faQuoteLeft,
    faUndo,
    faRedo,
    faCalendarAlt,
    faSyncAlt,
    faUserClock,
    faUserShield,
    faTable,
    faLineHeight,
    faBold,
    faItalic,
    faStrikethrough,
    faUnderline,
    faParagraph,
    faListUl,
    faListOl,
    faHeading,
    faInfinity,
    faTags,
    faClone,
    faArchive,
    faFilePdf,
    faFileExcel,
    faPageBreak,
    faPhone,
    faMobile,
    faHourglass,
    faShare,
    faLockAlt,
    faUnlockAlt,
    faInfoCircle,
    faCreditCard,
    faReceipt,
    faChartPie,
    faBookmark,
    faHeartRate,
    faBellPlus,
    faFileInvoice,
    faAlarmExclamation,
    faPercentage,
    faPercent,
    faShoppingCart,
    faCommentLines,
    faCheckSquare,
    faSquare,
    faLayerGroup,
    faCode,
    faFileImport,
    faWifi,
    faWifiSlash,
    faThumbsDown,
    faThumbsUp,
    faBrowser,
    faQrcode,
    faAlignLeft,
    faCoins,
    faBan,
    faEnvelope,
    faSortNumericUp,
    faKeyboard,
    faGlobeAmericas,
    faTasks,
    faFrown,
    faLock,
    faUser,
    faSchool,
    faCertificate,
    faUniversity,
    faGlobe,
    faWrench,
    faMapMarkerAlt,
    faClipboard,
    faMoneyBill,
    faVenusMars,
    faHandshake,
    faBuilding,
    faBadge,
    faArrowAltCircleRight,
    faGraduationCap,
    faBusinessTime,
    faHourglassHalf,
    faUserGraduate,
    faCompass,
    faPuzzlePiece,
    faStopwatch,
    faComments,
    faHeart,
	faChevronDown,
	faChevronRight,
	faToggleOn,
	faToggleOff,
	faTimesCircle,
	faExternalLink
} from '@fortawesome/pro-regular-svg-icons'

import {
    faThLarge,
    faPencilAlt,
    faLightbulb,
    faLink,
    faSave,
    faMale,
    faQuestion,
    faFemale,
    faTransgender,
    faBell as fasBell,
    faUserCircle as fasUserCircle,
    faUser as fasUser,

} from '@fortawesome/free-solid-svg-icons'

import {
    faFlame as falFlame,
    faUserCircle as falUserCircle,
    faBell as falBell,
    faCube as falCube,
    faFastForward as falFastForfaFastForward,
    faFileInvoiceDollar as falFileInvoiceDollar,
    faQuoteLeft as falQuoteLeft,
    faAddressCard as falAddressCard,
    faTags as falTags,
    faChartBar as falChartBar,
    faComment as falComment,
    faUser as falUser,
    faUsers as falUsers,
    faCog as falCog,
    faPlus as falPlus,
    faHeart as falHeart,
    faHeartRate as falHeartRate,
    faCircle as falCircle,
    faUserClock as falUserClock,
    faLayerPlus as falLayerPlus,
    faUsersClass as falUsersClass,
    faReceipt as falReceipt,
    faCreditCard as falCreditCard,
    faCheckCircle as falCheckCircle,
    faSearch as falSearch,
    faClock as falClock,
    faAlarmExclamation as falAlarmExclamation,
    faBars as falBars,
    faTimes as falTimes,
    faCommentLines as falCommentLines,
    faChartPie as falChartPie,
    faCogs as falCogs,
    faEnvelope as falEnvelope,
    faHandshake as falHandshake,
} from '@fortawesome/pro-light-svg-icons'

import {
    faFacebookF,
    faTwitter,
    faLinkedinIn,
    faWhatsapp
} from '@fortawesome/free-brands-svg-icons';

IconLibrary.add(
    faFacebookF,
    faTwitter,
    faLinkedinIn,
    faWhatsapp,
	faCopy,
    fasUser,
    faFlask,
    fasBell,
    faSchool,
    faInbox,
    faReply,
    faPencilAlt,
    fasUserCircle,
    faPen,
    faUser,
    faRocket,
    faThLarge,
    faCube,
    faCubes,
    faEllipsisV,
    faEllipsisH,
    faPlus,
    faLanguage,
    faMinus,
    faSmile,
    faFrown,
    faTasks,
    faPlusCircle,
    faMinusCircle,
    faClipboard,
    faPencil,
    faTrashAlt,
    faEye,
    faEyeSlash,
    faSearch,
    faArrowRight,
    faLongArrowRight,
    faArrowToRight,
    faArrowLeft,
    faLongArrowLeft,
    faArrowToLeft,
    faArrowUp,
    faArrowDown,
    faStream,
    faClipboardListCheck,
    faCheck,
    faCheckCircle,
    faTachometerAlt,
    faBars,
    faHome,
    faClock,
    faTruck,
    faUserCircle,
    faUserCog,
    faUserPlus,
    faIndustry,
    faServer,
    faTimes,
    faArrows,
    faSortDown,
    faSortUp,
    faSortAmountUp,
    faSortAmountDown,
    faFilter,
    faAngleDown,
    faAngleUp,
    faAngleRight,
    faAngleLeft,
    faLink,
    faListAlt,
    faTag,
    faHandshake,
    faExclamationTriangle,
    faExclamationCircle,
    faQuestion,
    faQuestionCircle,
    faUsers,
    faAddressCard,
    faGripVertical,
    faSignIn,
    faSignOut,
    faCog,
    faGenderless,
    faPaperPlane,
    faAt,
    faKey,
    faFileInvoiceDollar,
    faQuoteLeft,
    faUndo,
    faRedo,
    faCalendarAlt,
    faSyncAlt,
    faUserClock,
    faUserShield,
    faTable,
    faLineHeight,
    faBold,
    faItalic,
    faStrikethrough,
    faInfinity,
    faUnderline,
    faParagraph,
    faListUl,
    faListOl,
    faGlobeAmericas,
    faHeading,
    faTags,
    faClone,
    faArchive,
    faFilePdf,
    faFileExcel,
    faPageBreak,
    faPhone,
    faMobile,
    faHourglass,
    faShare,
    faLockAlt,
    faUnlockAlt,
    faInfoCircle,
    faCreditCard,
    faReceipt,
    faChartPie,
    faBookmark,
    faHeartRate,
    faBellPlus,
    faFileInvoice,
    faAlarmExclamation,
    faPercentage,
    faPercent,
    faShoppingCart,
    faCommentLines,
    faCheckSquare,
    faSquare,
    faLayerGroup,
    faCode,
    faFileImport,
    faWifi,
    faWifiSlash,
    faThumbsDown,
    faThumbsUp,
    faBrowser,
    faQrcode,
    faAlignLeft,
    faCoins,
    faBan,
    faEnvelope,
    faSortNumericUp,
    faKeyboard,
    faBuilding,
    falFlame,
    falUserCircle,
    falBell,
    falCube,
    falFastForfaFastForward,
    falFileInvoiceDollar,
    falQuoteLeft,
    falAddressCard,
    falTags,
    falChartBar,
    falUser,
    falUsers,
    falCog,
    falHeart,
    falHeartRate,
    falCircle,
    falUserClock,
    falLayerPlus,
    falUsersClass,
    falReceipt,
    falCreditCard,
    falCheckCircle,
    falSearch,
    falClock,
    falPlus,
    falAlarmExclamation,
    falBars,
    falTimes,
    falCommentLines,
    falChartPie,
    falCogs,
    faUserCircle,
    faEnvelope,
    faLock,
    faLightbulb,
    faSave,
    faMale,
    faFemale,
    faTransgender,
    faCertificate,
    faUniversity,
    faGlobe,
    faWrench,
    faMapMarkerAlt,
    falComment,
    falEnvelope,
    faMoneyBill,
    faVenusMars,
    falHandshake,
    faArrowAltCircleRight,
    faBadge,
    faGraduationCap,
    faBusinessTime,
    faHourglassHalf,
    faUserGraduate,
    faCompass,
    faPuzzlePiece,
    faStopwatch,
    faComments,
    faHeart,
	faChevronDown,
	faChevronRight,
	faToggleOn,
	faToggleOff,
	faTimesCircle,
	faExternalLink
)

Vue.component('o-icon', FontAwesomeIcon)
Vue.component('o-icon-layers', FontAwesomeLayers)
Vue.component('o-icon-layers-text', FontAwesomeLayersText)

export default IconLibrary
