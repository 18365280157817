<template>
  <o-dropdown :align="align">
    <a href="javascript:void(0)" slot="toggle">
      <div class="text-white p-0 d-flex align-items-center">
        <span class="mr-2">
          {{ $store.state.locale.toLowerCase() }}
        </span>
        <o-icon :icon="['far', 'globe']" size="lg"></o-icon>
      </div>
    </a>
    <o-dropdown-item :class="$store.state.locale === 'en' ? 'font-weight-bold' : ''" @click="changeLocaleTo('en')">English</o-dropdown-item>
    <o-dropdown-item :class="$store.state.locale === 'de' ? 'font-weight-bold' : ''" @click="changeLocaleTo('de')">Deutsch</o-dropdown-item>
  </o-dropdown>
</template>
<script>
export default {
  props: {
    align: {
      type: String
    }
  },
  methods: {
    changeLocaleTo(locale) {
      if ( this.$store.state.locale === locale ) return;
      if ( !this.$store.state.locales.includes(locale) ) return;

      this.$store.dispatch('changeLocale', locale);
    }
  }
}
</script>
