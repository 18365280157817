var render = function render(_c,_vm){return (_vm.props.to)?_c('router-link',{staticClass:"dropdown-item d-flex align-items-center",class:[
            _vm.props.active ? 'active' : null,
            _vm.props.disabled ? 'disabled' : null,
            _vm.data.class,
            _vm.data.staticClass
        ],attrs:{"target":_vm.props.target,"to":_vm.props.to}},[(_vm.props.icon)?_c('o-icon',{staticClass:"mr-2 ml-n1",attrs:{"icon":['far', _vm.props.icon],"fixed-width":""}}):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.props.kbd)?_c('div',{staticClass:"ml-auto"},[_c('kbd',{staticClass:"shortcut ml-3"},[_vm._v(_vm._s(_vm.props.kbd))])]):_vm._e()],2):_c('a',{staticClass:"dropdown-item d-flex align-items-center",class:[
            _vm.props.active ? 'active' : null,
            _vm.props.disabled ? 'disabled' : null,
            _vm.data.class,
            _vm.data.staticClass
        ],attrs:{"href":_vm.props.href || 'javascript:;',"target":_vm.props.href ? '_blank' : null},on:{"click":function($event){_vm.listeners['click'] ? _vm.listeners['click']($event) : null}}},[(_vm.props.icon)?_c('o-icon',{staticClass:"mr-2 ml-n1",attrs:{"icon":[_vm.props.iconStyle || 'far', _vm.props.icon],"fixed-width":""}}):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.props.kbd)?_c('div',{staticClass:"ml-auto"},[_c('kbd',{staticClass:"shortcut ml-3"},[_vm._v(_vm._s(_vm.props.kbd))])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }