<template>
    <footer class="bg-dark px-5 pt-3 pb-56 py-lg-3 text-muted text-center mt-auto rainbow-border-top relative">
        <div class="flex flex-col gap-x-2 gap-y-5 lg:flex-row items-center justify-center pb-3 pt-2 lg:!py-2">
            <div class="shrink-0">
                <strong class="text-white">{{ $t('app.title') }}</strong> • Copyright &copy; {{ new Date().getFullYear() }}
            </div>
	        <div class="lg:mx-12 flex flex-wrap gap-x-6 gap-y-1.5 justify-center">
	            <a :href="$t('footer.terms.href')" target="_blank" class="btn-link text-white">{{ $t('footer.terms.label') }}</a>
	            <a :href="$t('footer.privacy.href')" target="_blank" class="btn-link text-white">{{ $t('footer.privacy.label') }}</a>
	            <a :href="$t('footer.help.href')" target="_blank" class="btn-link text-white">{{ $t('footer.help.label') }}</a>
		        <a v-if="$org" href="https://calendly.com/apropos-jobs/beratung"
		           target="_blank" :title="$t('link.get-support-title')"
		           class="btn-link text-white">
			        <o-icon :icon="['far', 'phone']" class="mr-1" />
			        {{ $t('link.get-support') }}
		        </a>
	        </div>
	        <div>
                <language-switcher align="bottom-end"/>
	        </div>
        </div>
    </footer>
</template>

<script>
import LanguageSwitcher from './language-switcher.vue';

export default {
    components: {LanguageSwitcher}
}
</script>
