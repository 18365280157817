export default [
  {
    name: 'motivator',
    hexColor: '#ef7950'
  },
  {
    name: 'inspirator',
    hexColor: '#fecc30'
  },
  {
    name: 'advisor',
    hexColor: '#bbca4a'
  },
  {
    name: 'supporter',
    hexColor: '#68bc8d'
  },
  {
    name: 'coordinator',
    hexColor: '#49b4bc'
  },
  {
    name: 'observer',
    hexColor: '#6489b9'
  },
  {
    name: 'reformer',
    hexColor: '#838091'
  },
  {
    name: 'initiator',
    hexColor: '#d43949'
  }
]
