<template>
  <div>
    <a href="javascript:;" @click="active = !active" class="d-flex align-items-center p-1" :style="`background-color: ${titleBackdrop}`">
        <o-icon v-if="active" :icon="['far', 'minus']" />
        <o-icon v-else :icon="['far', 'plus']" />
        <h2 class="mb-0 ml-1"><strong>{{ title }}</strong></h2>
    </a>
    <div :class="{'p-2': active}">
        <expand-transition>
            <slot v-if="active" />
        </expand-transition>
    </div>
  </div>
</template>

<script>
export default {
    props: ['title', 'titleBackdrop', 'preSelected'],
    components: {
        ExpandTransition: () => import('~/components/UI/Transitions/ExpandTransition.vue')
    },

    data() {
        return {
            active: null
        }
    },
    created() {
        this.active = this.preSelected
    }
}
</script>