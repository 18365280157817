<template>
    <o-form-group :label="label" :required="required" :help-text="helpText">
        <select 
            class="custom-select" 
            :class="inputClass"
            :value="value"
            @input="$emit('input', $event.target.value)" 
            :disabled="disabled"
            :required="required">
            <option v-if="placeholder" :value="null" disabled="disabled">{{ placeholder }}</option>
            <option v-if="nullOption" :value="null">{{ nullOption }}</option>
            <option 
                v-for="(option, index) in options" 
                :key="`option-${index}`"
                :value="option[valueField]"
                :disabled="option[disabledField]">
                {{ option[labelField] }}
            </option>
        </select>
    </o-form-group>
</template>
<script>
import OFormGroup from './Group.vue'
export default {
    components: {
        OFormGroup
    },

    props: {
        label: {
            type: String,
            default: null,
        },
        value: {
            default: null,
        },
        options: {
            type: Array,
            default: () => []
        },
        nullOption: {
            type: String,
            default: null
        },
        labelField: {
            type: String,
            default: 'label'
        },
        valueField: {
            type: String,
            default: 'value'
        },
        disabledField: {
            type: String,
            default: 'disabled'
        },
        placeholder: {
            type: String,
            default: null
        },
        helpText: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        selectFirst: {
            type: Boolean,
            default: false,
        },
        inputClass: {
            type: [String, Object, Array],
            default: null
        }
    },

    watch: {
        options: {
            handler() {
                if (!this.value && this.selectFirst && this.options.length > 0) {
                    this.$emit('input', this.options[0][this.valueField])
                }
            },
            immediate: true
        }
    }
}
</script>
