<template>
    <button 
        ref="button" 
        :type="submit ? 'submit' : 'button'" 
        class="btn align-items-center" 
        :class="[
            `btn${outline ? '-outline' : ''}-${type}`, 
            block ? 'btn-block' : null,
            size ? `btn-${size}` : null,
            (icon || iconAfter) && !$slots.default ? 'btn-icon' : null
        ]" 
        @click="$emit('click', $event)"
        :tabindex="preventFocus ? '-1' : null">
        <o-icon v-if="icon" :icon="[iconPrefix, icon]" :size="iconSize" :class="{ 'mr-1': $slots.default }" :fixed-width="(icon || iconAfter) && !$slots.default"></o-icon>
        <slot></slot>
        <o-icon v-if="iconAfter" :icon="[iconPrefix, iconAfter]" :size="iconSize" :class="{ 'ml-1': $slots.default }" :fixed-width="(icon || iconAfter) && !$slots.default"></o-icon>
    </button>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: 'link'
        },
        icon: {
            type: String,
            default: null,
        },
        iconAfter: {
            type: String,
            default: null,
        },
        iconPrefix: {
            type: String,
            default: 'far',
        },
        iconSize: {
            type: String,
            default: null,
        },
        submit: {
            type: Boolean,
            default: false,
        },
        block: {
            type: Boolean,
            default: false,
        },
        outline: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: null
        },
        preventFocus: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        focus: function () {
            this.$refs.button.focus()
        }
    }
}
</script>
