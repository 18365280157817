<template>
    <OFormGroup :label="label" :required="required" :help-text="helpText">
        <input 
            ref="input"
            type="text"
            class="form-control" 
            :class="{
                'form-control-sm': size === 'sm',
                'form-control-lg': size === 'lg',    
            }"
            :id="`datepicker-${label}`" 
            :value="value"
            :aria-describedby="`datepicker-${label}-help`" 
            :placeholder="placeholder"
            :required="required"
            :disabled="disabled"
            autocomplete="none">
    </OFormGroup>
</template>
<script>
import flatpickr from "flatpickr"
import { German } from "flatpickr/dist/l10n/de"
import OFormGroup from './Group.vue'
import Cleave from 'cleave.js'

export default {
    components: {
        OFormGroup
    },
    props: {
        label: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: 'TT.MM.JJJJ',
        },
        value: {
            default: null,
        },
        helpText: {
            type: String,
            default: null,
        },
        options: {
            type: Object,
            default: () => {}
        },
        mode: {
            type: String,
            default: 'single'
        },
        time: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: null,
        },
        showIcon: {
            type: Boolean,
            default: true,
        }
    },

    data() {
        return {
            instance: null,
        }
    },

    mounted() {
        const self = this
        const defaultConfig = {
            allowInput: false,
            altInput: true,
            altFormat: 'd.m.Y',
            time_24hr: true,
            mode: this.mode,
            enableTime: this.time,
            locale: German,

            onChange(selectedDates, dateStr, instance) {
                if(selectedDates.length > 1) {
                    self.$emit('input', dateStr.split(' bis '))
                } else if (instance.config.mode != 'range') {
                    self.$emit('input', dateStr)
                }
            }
        }

        const mergedConfig = Object.assign(defaultConfig, this.options)
        this.instance = flatpickr(this.$refs.input, mergedConfig)

        if (this.value && this.value.includes(',')) {
            this.instance.setDate(this.value.split(','))
        } else if (this.value) {
            this.instance.setDate(this.value)
        }

        if (!this.instance.isMobile) {
            this.$nextTick(() => {
                new Cleave(this.instance.input.nextSibling, {
                    date: true,
                    delimiter: '.',
                    datePattern: ['d', 'm', 'Y']
                })
            })
        }
    },

    destroyed() {
        this.instance.destroy()
    },

    watch: {
        value(val) {
            this.instance.setDate(val)
        },

        options: {
            handler(options) {
                Object.keys(options).forEach(key => {
                    this.instance.set(key, options[key])
                })
            },
            deep: true
        }
    }
}
</script>
