<template>
    <o-form-group :label="label" :helpText="helpText">
        <div class="custom-control custom-switch">
            <input :id="`form-switch-${id}`"
                :name="name"
                type="checkbox" 
                class="custom-control-input" 
                :disabled="disabled"
                :checked="value"
                @input="$emit('input', $event.target.checked)">
            <label :for="`form-switch-${id}`" class="custom-control-label d-inline-block">{{ states[value ? 0 : 1] || '' }}</label>
        </div>
    </o-form-group>
</template>
<script>
import OFormGroup from './Group.vue'
export default {
    components: {
        OFormGroup,
    },

    props: {
        value: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        helpText: {
            type: String,
            default: null
        },
        states: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        id() {
            return this.name || this.label || parseInt(Math.random() * 1000)
        }
    }
}
</script>
