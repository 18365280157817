<template>
    <transition name="slideout">
        <div v-if="open" class="slideout">
            <div class="slideout-backdrop" @click="$emit('close')"></div>
            <div class="slideout-sidebar">
                <div class="slideout-closer" @click="$emit('close')">
                    <o-icon :icon="['far', 'times']" size="lg"></o-icon>
                </div>

                <h2 class="mb-3">
                    {{ $t('general.notifications') }}
                    <span v-if="notifications.length > 0" class="text-muted ml-2">{{ notifications.length }}</span>
                </h2>

                <div v-if="notifications.length === 0">
                    <p>{{ $t('notification.no-unread') }}</p>
                </div>
                <div v-else class="slideout-sidebar-list">
                    <div
                        class="slideout-sidebar-item"
                        v-for="notification in dynamicNotifications(notifications)"
                        :key="notification.id" @click.stop>
                        <div class="d-flex justify-content-between align-items-start mb-2">
                            <div>
                                <strong>{{ notification.data.title }}</strong><br />
                                <span class="text-muted">{{ $formatDateDistance(notification.created_at) }}</span>
                            </div>
                            <o-button type="link" icon="times" class="mr-n3" @click="$store.dispatch('notification/dismiss', notification)" />
                        </div>

                        <div v-html="notification.data.message" class="slideout-sidebar-message"></div>

                        <div v-if="notification.data.url" class="mt-2">
                            <nuxt-link :to="notification.data.url" @click.native="$store.dispatch('notification/dismiss', notification)">
                                <o-button type="outline-primary" block>{{ $t('crud.show') }}</o-button>
                            </nuxt-link>
                        </div>

                        <component 
                            :is="types[notification.type]" 
                            @dismiss="$store.dispatch('notification/dismiss', notification)"
                            :notification="notification"
                            class="mt-2" />
                    </div>

                    <o-button 
                        type="light" 
                        class="mt-3" 
                        icon="trash-alt" 
                        block
                        @click="$store.dispatch('notification/dismissAll')">
                        {{ $t('notification.mark-all-as-unread') }}
                    </o-button>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import InviteUser from './types/inviteUser.vue'

export default {
    props: {
        open: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            fetchInterval: null,
            types: {
                'App\\Notifications\\InviteUser': InviteUser,
            }
        }
    },

    mounted() {
        if (this.$user) {
            this.$store.dispatch('notification/fetch').then(() => {
                if (this.notifications.length > 0) {
                    this.$store.dispatch('notification/toggleSlideout')
                }
            })

            setInterval(() => {
                this.$store.dispatch('notification/fetch')
            }, 30000)
        }
    },

    destroyed() {
        clearInterval(this.fetchInterval)
    },

    methods: {
        dynamicNotifications(notifications) {
            if ( notifications.length === 0 ) return [];

            // translate interface strings
            const translated = notifications.map(notification => {
                const clonedNotification = {...notification};
                clonedNotification.data = {...notification.data};
                clonedNotification.data.title = this.replaceInterfaceStrings(notification.data.title);
                clonedNotification.data.message = this.replaceInterfaceStrings(notification.data.message);

                return clonedNotification
            });

            // replace dynamic values and return
            return translated.map(notification => this.replaceDynamicValues(notification));
        },
        replaceInterfaceStrings(string) {
            if ( !string.includes(':::') ) return string;
            return string.replace(/:::(.*?):::/g, (match, innerValue) => {
                return this.$t(innerValue);
            });
        },
        replaceDynamicValues(notification) {
            const dynamicValues = ['email','company','role','firstname','jobname'];
            dynamicValues.forEach(value => {
                if (notification.data[value]) {
                    notification.data.message = notification.data.message.replaceAll(`:${value}`, notification.data[value]);
                    notification.data.title = notification.data.title.replaceAll(`:${value}`, notification.data[value]);
                }
            });
            return notification
        }
    },

    computed: {
        notifications() {
            return this.$store.state.notification.unread;
        },
    },
}
</script>