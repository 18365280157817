export const state = () => {
    return {
        current: null,
        organizations: []
    }
}

export const mutations = {
    setOrganization(state, organization) {
        state.current = organization
    },

    setOrganizations(state, organizations) {
        state.organizations = organizations
    },
}

export const getters = {

}

export const actions = {
    create({ commit }, organization) {
        return this.$axios.$post('organization', organization).then(data => {
            commit('setOrganization', data)
            return data
        })
        .then( () => {
            this.$router.push('/welcome');
        })
        .catch(() => {});
    },

    fetch({ commit }, id) {
        return this.$axios.$get('organization/' + id).then(data => {
            commit('setOrganization', data)
            return data
        })
    },

	fetchBySlug({ commit }, slug) {
		return this.$axios.$get(`organization/slug/${slug}`).then(data => {
			commit('setOrganization', data);
			return data;
		});
	},

	update({ commit, dispatch }, organization) {
        return this.$axios.$put('organization/' + organization.id, organization).then(data => {
            commit('setOrganization', data)
            dispatch('user/getUser', null, { root: true })
            return data
        })
    },

    invite({ commit, dispatch }, { organization, email, role = null }) {
        return this.$axios.$post(`organization/${organization.id}/invite`, { email, role }).then(data => {
            commit('setOrganization', data)
            dispatch('toast/push', {
                title: this.app.i18n.t('invitation.sent'),
                message: this.app.i18n.t('invitation.sent-text', { email }),
                type: 'success'
            }, { root: true })
        })
        .catch(() => {});
    },

    uninvite({ commit, dispatch }, { organization, user }) {
        return this.$axios.$get(`organization/${organization.id}/uninvite/${user.id}`).then(data => {
            commit('setOrganization', data)
            dispatch('toast/push', {
                title: this.app.i18n.t('organization.member.uninvited'),
                message: this.app.i18n.t('organization.member.uninvited-text', { user: user.fullName, organization: organization.name }),
                type: 'warning'
            }, { root: true })
        })
        .catch(() => {});
    },

    changeUserRole({ commit, dispatch }, { organization, user, role }) {
        return this.$axios.$get(`organization/${organization.id}/role/${user.id}/${role}`).then(data => {
            commit('setOrganization', data)
            dispatch('toast/push', {
                title: this.app.i18n.t('organization.member.role-changed'),
                message: this.app.i18n.t('organization.member.role-changed-text', { user: user.fullName, role: this.app.i18n.t('organization.role.' + role) }),
                type: 'success'
            }, { root: true })
        })
    },

    deleteInvitation({ commit, dispatch }, invitation) {
        return this.$axios.$delete(`invitation/${invitation.id}`).then(data => {
            commit('setOrganization', data)
            dispatch('toast/push', {
                title: this.app.i18n.t('invitation.deleted'),
                message: this.app.i18n.t('invitation.deleted-text', { email: invitation.email }),
                type: 'warning'
            }, { root: true })
        })
        .catch(() => {});
    },

    acceptInvitation({ commit, dispatch }, invitation) {
        return this.$axios.$get(`invitation/${invitation.id}/accept`).then(data => {
            commit('user/setUser', data, { root: true })
            dispatch('toast/push', {
                title: this.app.i18n.t('organization.joined', { organization: invitation.organization.name }),
                type: 'success'
            }, { root: true })
        })
    },

    declineInvitation({ commit, dispatch }, invitation) {
        return this.$axios.$get(`invitation/${invitation.id}/decline`).then(data => {
            commit('user/setUser', data, { root: true })
            dispatch('toast/push', {
                title: this.app.i18n.t('invitation.declined', { organization: invitation.organization.name }),
            }, { root: true })
        })
    },

    uploadLogo({ commit, dispatch }, { organization, file, onProgress = null }) {
        return dispatch('media/upload', { file, endpoint: `organization/${organization.id}/logo`, onProgress }, { root: true }).then(data => {
            commit('setOrganization', data)
        })
    },
}
