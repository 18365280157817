<template>
<div class="dropdown d-inline-block" v-click-outside="close">
	<div class="dropdown-button" ref="button" @click="toggle()">
		<slot name="toggle">
			<o-button type="light" class="btn-icon">
				<o-icon :icon="['far', 'ellipsis-v']" fixed-width></o-icon>
			</o-button>
		</slot>
	</div>
	<transition name="dropdown">
		<div
			ref="menu"
			class="dropdown-menu mt-2"
			:class="{
                'show': isOpen,
                'dropdown-menu-limit': limit
            }"
			aria-labelledby="dropdownMenuButton"
			@click="dropdownItemClicked">
			<slot :toggle="toggle"></slot>
		</div>
	</transition>
	<transition name="dropdown-backdrop">
		<div v-if="isOpen" class="dropdown-backdrop" @click="close()"></div>
	</transition>
</div>
</template>
<script>
import OButton from './Button.vue'
import {createPopper} from '@popperjs/core'

export default {
	props: {
		align: {
			type: String,
			default: 'bottom-start'
		},

		limit: {
			type: Boolean,
			default: false
		},
	},

	data() {
		return {
			isOpen: false,
			popper: null,
		}
	},

	components: {
		OButton,
	},

	methods: {
		toggle() {
			this.isOpen = !this.isOpen
			this.$emit('dropdownIsOpen', this.isOpen)
		},

		open() {
			this.isOpen = true
			this.$emit('dropdownIsOpen', this.isOpen)
		},

		close() {
			this.isOpen = false
			this.$emit('dropdownIsOpen', this.isOpen)
		},

		dropdownItemClicked(event) {
			if (event.target.closest('.dropdown-item')) {
				this.close()
			}
		}
	},

	watch: {
		isOpen(open) {
			if (!this.$breakpoint('md')) {
				return
			}

			this.$nextTick(() => {
				if (open) {
					this.popper = createPopper(this.$refs.button, this.$refs.menu, {
						placement: this.align
					})
				} else {
					this.popper.destroy()
					this.popper = null
				}
			})
		}
	}
}
</script>
