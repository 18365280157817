<template>
    <o-container narrow class="text-center py-5">
        <img v-if="statusCode !== 404" src="/illu_error.svg" class="d-block mx-auto mb-5" style="width: 10rem;">

        <div v-if="statusCode === 404">
            <div class="prehead text-primary">Error 404</div>
            <h1 class="mb-2">{{ $t('error.404.title') }}</h1>
            <div class="lead">{{ $t('error.404.message') }}</div>

            <a href="/"><o-button type="light" class="mt-4">{{ $t('app.dashboard') }}</o-button></a>
        </div>

        <div v-else>
            <div class="prehead text-danger">Error {{ statusCode }}</div>
            <h1 class="mb-2">{{ $t(`error.${statusCode}.title`) }}</h1>
            <pre class="lead d-inline-block my-2 text-danger text-wrap mw-100">{{ message }}</pre>
            <div v-html="$t('error.informed')"></div>

            <o-button type="dark" icon="comment-lines" class="mt-4 mr-2" @click="showReportDialog()">{{ $t('error.feedback') }}</o-button>
            <o-button type="light" icon="sync-alt" class="mt-4 mr-2" @click="refreshPage()">{{ $t('crud.reload') }}</o-button>
            <a :href="`mailto:tobija.fischer@apropos.jobs?subject=${$t(`error.${statusCode}.title`)} auf ${$route.fullPath}`" hidden><o-button type="link" class="mt-4">{{ $t('app.contact-support') }}</o-button></a>

			<a href="/auth/logout"><o-button type="light" class="mt-4">{{ $t('auth.signing-out-title') }}</o-button></a>
        </div>
    </o-container>
</template>

<script>
import * as Sentry from '@sentry/browser'

export default {
    name: 'NuxtError',
    props: ['error'],
    layout: 'guest',

    computed: {
        statusCode () {
            return (this.error && this.error.statusCode) || 500
        },
        message () {
            return this.error.message || '<%= messages.client_error %>'
        }
    },
    head () {
        return {
            title: this.message,
        }
    },

    mounted() {
        if (this.statusCode === 500
            && this.error.message.includes('Loading chunk ')
            && this.error.message.includes(' failed.')
            && window.location.hash !== '#retry') {
                // the chunk might no longer be available due to a recent redeployment of the page
                // mark the page to don't trigger reload infinitely
                window.location.hash = '#retry'
                window.location.reload(true)
        }
    },

    methods: {
        refreshPage() {
            window.location.reload()
        },

        showReportDialog() {
            Sentry.showReportDialog({
                lang: store.state.locale ?? 'de',
                user: {
                    email: this.$user.email,
                    name: this.$user.fullName,
                }
            })
        }
    }
}
</script>
