import Vue from 'vue'

export const state = () => ({
    applicantProfile: null,
})

export const getters = {
    applicantProfile(state) {
        return state.applicantProfile
    },
}

export const actions = {

    async fetch({commit}) {
        await this.$axios.$get(`/applicant-profile`).then(data => {
            commit('setApplicantProfile', data)
        }).catch(() => {
            commit('setApplicantProfile', null)
        })
    },

    async needApplicantProfile({ state, dispatch }) {
        if (!state.applicantProfile) {
            await dispatch('fetch')
        }

        return state.applicantProfile
    },

    async store({ commit }, item) {
        return await this.$axios.$post(`/applicant-profile`, item).then(data => {
            commit('setApplicantProfile', data)
            return data
        })
    },

    async update({dispatch, commit}, applicantProfile) {

        let languages

        if (applicantProfile.languages) {
            languages = applicantProfile.languages.map(language => {
                return {
                    'id': language.id,
                    'attributes': {
                        'niveau': language.niveau,
                    }
                }
            })
        }

        let educationTypes = {}
            
        if (applicantProfile.educationTypes) {
            educationTypes = applicantProfile.educationTypes.map(educationType => {
                return {
                    'id': educationType.id,
                    'attributes': {
                        'name': educationType.name,
                        'end_year': educationType.end_year,
                    }
                }
            });
        }

        let provinces = {}
            
        if (applicantProfile.provinces) {
            provinces = applicantProfile.provinces.map(province => {
                return {
                    'id': province.id,
                    'attributes': {}
                }
            });
        }

        let data = {
            'id': applicantProfile.id,
            'address': applicantProfile.address,
            'address_line_2': applicantProfile.address_line_2,
            'city': applicantProfile.city,
            'zip': applicantProfile.zip,
            'country': applicantProfile.country,
            'url': applicantProfile.url,
            'gender': applicantProfile.gender,
            'salary': applicantProfile.salary,
            'nationality': applicantProfile.nationality,
            'birthday': applicantProfile.birthday,
            'pensum': applicantProfile.pensum,
            'active': applicantProfile.active,
            'available_from': applicantProfile.available_from,
            'start_date': applicantProfile.start_date,
            'provinces': provinces,
            'languages': languages,
            'education_types': educationTypes,
            'employment_type_id': applicantProfile.employment_type_id,
            'industry_id': applicantProfile.industry_id,
            'job_position_id': applicantProfile.job_position_id,
            'job_position': applicantProfile.jobPosition,
            'occupational_field_id': applicantProfile.occupational_field_id,
            'personality': applicantProfile.personality,
        }

        await this.$axios.$patch(`/applicant-profile/${data.id}`, data).then(data => {
            commit('setApplicantProfile', data)
            dispatch('toast/push', {
                title: data.name || this.app.i18n.t('crud.updated'),
                message: this.app.i18n.t('crud.updated-text'),
                type: 'success'
            }, { root: true })
        })
    
    },

    async delete({ commit }, id) {
        await this.$axios.$delete(`/applicant-profile/${id}`).then(data => {
            commit('removeOffer', id)
        })
    },
}

export const mutations = {
    setApplicantProfile: (state, data) => state.applicantProfile = data,
    removeOffer: (state, id) => Vue.delete(state.applicantProfile, state.applicantProfile.indexOf(state.applicantProfile.find(i => i.id == id)))
}