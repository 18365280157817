import crudStoreFactory from '~/util/crud/storeFactory'

const crudStore = new crudStoreFactory('offer', {
    include: ['project', 'company.media', 'contact']
})

export const state = () => {
    return crudStore.state()
}

export const getters = crudStore.getters()

export const mutations = crudStore.mutations()

export const actions = {
    ...crudStore.actions(),

    async sendToClient({ dispatch, commit }, { offer, email, message, ccToOwner }) {
        return this.$axios.$post(`offer/${offer.id}/send`, { email, message, ccToOwner }).then(item => {
            commit('UPDATE_OR_ADD_ITEM', item)

            return dispatch('toast/push', {
                title: this.app.i18n.t('offer.sent-to-client'),
                message: this.app.i18n.t('offer.sent-to-client-text'),
                type: 'success'
            }, { root: true })
        })
    },

    async createInvoice({ dispatch }, { offer, data }) {
        return this.$axios.$post('invoice', { fromOfferId: offer.id, ...data }).then(item => {
            return item
        })
    }
}