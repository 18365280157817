<template>
    <div class="toast" 
        :class="{ 'align-items-center': !($slots.default || message) }" 
        @click="$emit('dismiss')">
        <div v-if="icon || defaultIcon" :class="`text-${type}`" class="mr-3">
            <o-icon :icon="['far', icon || defaultIcon]" size="lg" />
        </div>
        <div class="mr-3">
            <h3 v-if="title" class="text-white">{{ title }}</h3>
            <div v-if="$slots.default || message">
                <slot>
                    <ul v-if="Array.isArray(message)" class="list-unstyled m-0">
                        <li v-for="(m, i) in message" :key="i">{{ m }}</li>
                    </ul>
                    <div v-else>{{ message }}</div>
                </slot>
            </div>
        </div>
    </div>
</template>
<script>
import OButton from './Button.vue'
export default {
    components: {
        OButton
    },

    props: {
        title: {
            type: String,
            default: null
        },
        message: {
            type: [String, Array],
            default: null,
        },
        type: {
            type: String,
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
        persist: {
            type: Boolean,
            default: false,
        },
        lifetime: {
            type: Number,
            default: 5000,
        }
    },

    mounted() {
        if(!this.persist) {
            setTimeout(() => this.$emit('dismiss'), this.lifetime)
        }
    },

    computed: {
        createdAt() {
            const d = new Date();
            const hour = d.getHours() < 10
                ? `0${d.getHours()}`
                : d.getHours();

            const minute = d.getMinutes() < 10
                ? `0${d.getMinutes()}`
                : d.getMinutes();

            const second = d.getSeconds() < 10
                ? `0${d.getSeconds()}`
                : d.getSeconds();

            return `${hour}:${minute}:${second}`;
        },

        defaultIcon() {
            if (this.type === 'success') return 'check'
            if (this.type === 'info') return 'info-circle'
            if (this.type === 'warning') return 'exclamation-triangle'
            if (this.type === 'danger') return 'exclamation-triangle'
            return null
        }
    }
}
</script>
