<template>
    <div class="min-vh-100 d-flex flex-column justify-content-between">
        <div class="d-flex align-items-md-center">
            <div class="container px-3 mb-5">
                <div class="row justify-content-center">
                    <div class="col-md-9 col-lg-7 col-xl-6 col-hd-5">
                        <nuxt />
                    </div>
                </div>
            </div>
            <toast-container-component />
        </div>
        <footer-component />
    </div>
</template>
<script>
import ToastContainerComponent from '~/components/toast-container.vue'
import FooterComponent from '~/components/footer.vue'
export default {
	head() {
		return {
			meta: [
				{ hid: 'robots', name: 'robots', content: 'index, follow' },
			],
		}
	},

    middleware: ['outdatedBrowser'],

    components: {
        ToastContainerComponent,
        FooterComponent,
    },
}
</script>
