export const state = () => {
    return {
        isOverlayOpen: false,
        results: []
    }
}

export const getters = {
    getGroupedResults(state) {
        return Object.keys(state.results).map(group => {
            return {
                name: group,
                items: state.results[group]
            }
        })
    },

    getAllResults(state) {
        return Object.keys(state.results).reduce((carry, group) => {
            return carry.concat(state.results[group])
        }, [])
    }
}

export const mutations = {
    SET_IS_OVERLAY_OPEN(state, open) {
        state.isOverlayOpen = open
    },

    SET_RESULTS(state, results = []) {
        state.results = results
    }
}

export const actions = {
    async fetchResults({ commit }, query) {
        let results = await this.$axios.$get(`search?q=${encodeURIComponent(query)}`)

        commit('SET_RESULTS', results)

        return results
    },

    clearResults({ commit }) {
        commit('SET_RESULTS', [])
    },

    toggleOverlay({ commit, state }) {
        commit('SET_RESULTS', [])
        commit('SET_IS_OVERLAY_OPEN', !state.isOverlayOpen)
    }
}