export const state = () => {
    return {
        toasts: [],
    }
}

export const mutations = {
    addToast(state, toast) {
        toast.id = new Date().valueOf()
        state.toasts.unshift(toast)
    },

    removeToast(state, toast) {
        state.toasts.splice(state.toasts.indexOf(toast), 1)
    },
}

export const actions = {
    push({ commit, state }, toast) {
        commit('addToast', toast)
    },

    dismiss({ commit }, toast) {
        commit('removeToast', toast)
    }
}