import Vue from 'vue'
import { createPopper } from '@popperjs/core'

const tooltips = []

function registerTooltip(config) {
    config.el.addEventListener('mouseenter', () => config.open())
    config.el.addEventListener('focus', () => config.open())

    config.el.addEventListener('mouseleave', () => config.close())
    config.el.addEventListener('blur', () => config.close())
    config.el.addEventListener('click', () => config.close())

    tooltips.push(config)
}

function unregisterTooltip(el) {
    const config = tooltips.find(t => t.el === el)
    
    if (config) {
        el.removeEventListener('mouseenter', () => config.open())
        el.removeEventListener('focus', () => config.open())

        el.removeEventListener('mouseleave', () => config.close())
        el.removeEventListener('blur', () => config.close())
        el.removeEventListener('click', () => config.close())

        config.tooltip.parentNode.removeChild(config.tooltip)
    }
}

Vue.directive('tooltip', {
    inserted(el, binding) {
        const tooltip = document.createElement('div')
        tooltip.className = 'tooltip'
        tooltip.innerHTML = `<div class="tooltip-body">${binding.value}</div>`
        document.body.appendChild(tooltip)

        const config = {
            el,
            tooltip,
            popper: null,
            open() {
                this.tooltip.classList.add('tooltip-show')
                this.popper = createPopper(this.el, this.tooltip, {
                    placement: binding.arg || 'top'
                })
            },
            close() {
                config.tooltip.classList.remove('tooltip-show')
                if (config.popper) {
                    config.popper.destroy()
                    config.popper = null
                }
            }
        }

        registerTooltip(config)
    },

    unbind(el, binding) {
        unregisterTooltip(el)
    }
})