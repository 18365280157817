import Storage from '~/util/storage'

const sidebarBreakpointQuery = 'screen and (min-width: 1200px)'
// default language to 'en' except when it's explicitly set to 'de'
const defaultLocale = navigator.language.split('-')[0] === 'de'
    ? 'de'
    : 'en';

export const state = () => {
    return {
        locales: ['de', 'en'],
        locale: window.localStorage.getItem('locale') || defaultLocale,
        sidebarOpen: window.matchMedia(sidebarBreakpointQuery).matches || false,
        validationErrors: [],
        showOnboarding: Storage.get('showOnboarding', true),
    }
}

export const mutations = {
    setLocale(state, locale) {
        if (state.locales.includes(locale)) {
            state.locale = locale
            window.localStorage.setItem('locale', locale)
            this.app.i18n.locale = locale
        }
    },

    setSidebarOpen(state, open) {
        state.sidebarOpen = open
    },

    toggleSidebar(state) {
        state.sidebarOpen = !state.sidebarOpen
    },

    setValidationErrors(state, errors) {
        state.validationErrors = errors
    },

    setShowOnboarding(state, show) {
        state.showOnboarding = show
        Storage.set('showOnboarding', show)
    }
}

export const getters = {

}

export const actions = {
    closeOnSmallDevices({ commit }) {
        if (!window.matchMedia(sidebarBreakpointQuery).matches) {
            commit('setSidebarOpen', false)
        }
    },

    changeLocale({ commit }, locale) {
        commit('setLocale', locale)
    },

    showOnboarding({ commit }) {
        commit('setShowOnboarding', true)
    },

    dismissOnboarding({ commit }) {
        commit('setShowOnboarding', false)
    }
}
