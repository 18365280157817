import Vue from 'vue'

Vue.mixin({
    methods: {
        $can(permission) {
            // Need at least one permission
            if (permission.indexOf('|') !== -1) {
                const permissions = permission.split('|')
                return permissions.some(p => this.$store.getters['user/hasPermissionTo'](p))
            }

            // Needs all permissions
            if (permission.indexOf(',') !== -1) {
                const permissions = permission.split(',')
                return permissions.every(p => this.$store.getters['user/hasPermissionTo'](p))
            }

            // Need a single permission
            return this.$store.getters['user/hasPermissionTo'](permission)
        }
    }
})