const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['closeSidebarOnSmallDevices'] = require('../middleware/closeSidebarOnSmallDevices.js')
middleware['closeSidebarOnSmallDevices'] = middleware['closeSidebarOnSmallDevices'].default || middleware['closeSidebarOnSmallDevices']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['isOrganization'] = require('../middleware/isOrganization.js')
middleware['isOrganization'] = middleware['isOrganization'].default || middleware['isOrganization']

middleware['outdatedBrowser'] = require('../middleware/outdatedBrowser.js')
middleware['outdatedBrowser'] = middleware['outdatedBrowser'].default || middleware['outdatedBrowser']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['superuser'] = require('../middleware/superuser.js')
middleware['superuser'] = middleware['superuser'].default || middleware['superuser']

middleware['userSetupComplete'] = require('../middleware/userSetupComplete.js')
middleware['userSetupComplete'] = middleware['userSetupComplete'].default || middleware['userSetupComplete']

middleware['verified'] = require('../middleware/verified.js')
middleware['verified'] = middleware['verified'].default || middleware['verified']

export default middleware
