<template>
    <o-dropdown @dropdownIsOpen="companyDropdown">
        <template #toggle>
            <o-button :icon-after="icon ? 'angle-down' : null" class="p-0 small font-weight-bold !flex gap-2" :type="$org.is_support_login ? 'info' : 'link'">
<!--	            <span v-if="$org.logo" class="rounded-full bg-black w-8 h-8 bg-cover border-2" :style="`background-image: url(${ $org.logo.url })`"></span>-->
				<span>
					<o-icon class="text-xl" :icon="['far', 'building']" />
					<o-icon :icon="['far', 'chevron-down']" class="transition-all text-xs ml-0.5"
							:class="companyDropdownOpen ? 'rotate-180' : ''"/>
				</span>
            </o-button>
        </template>

        <o-dropdown-header>{{ $org.name }}</o-dropdown-header>
        <o-dropdown-item :to="`/${$org.slug}`" target="_blank" icon="external-link">{{ $t('organization.profile') }}</o-dropdown-item>
        <o-dropdown-item v-if="$can('edit organization')" to="/organization/profile" icon="industry">{{ $t('organization.profile-edit') }}</o-dropdown-item>
        <o-dropdown-item to="/organization/user" icon="users">{{ $t('organization.members') }}</o-dropdown-item>
        <o-dropdown-item to="/organization/pricing" icon="money-bill">{{ $t('link.pricing') }}</o-dropdown-item>
        <div class="dropdown-divider"></div>

	    <div v-if="$user.organizations.length > 1">
		    <o-dropdown-header>{{ $t('organization.change') }}</o-dropdown-header>
		    <o-dropdown-item
			    v-for="organization in $user.organizations.filter(org => org.id !== $user.current_organization.id && org.name)"
			    :key="organization.id"
			    icon="angle-right"
			    @click="$store.dispatch('user/changeOrganization', organization)">
			    {{ organization.name }}
		    </o-dropdown-item>
		    <o-dropdown-item v-if="$user.is_superuser" icon="user-shield" to="/superuser/change-organization" class="text-info">Support Login</o-dropdown-item>
	    </div>
        <div v-if="canCreateNewOrganization">
            <div v-if="$user.organizations.length > 1" class="dropdown-divider"></div>
            <o-dropdown-item  to="/organization-create" icon="plus">{{ $t('organization.create-new-organization') }}</o-dropdown-item>
        </div>
    </o-dropdown>
</template>

<script>
export default {
    props: {
        icon: {
            type: Boolean,
            default: false
        }
    },
	data() {
		return {
			companyDropdownOpen: false
		}
	},
    computed: {
        canCreateNewOrganization() {
            if ( !this.$user?.current_organization?.subscription) return false;

            // check if organization is on a free plan
            if ( this.$user.current_organization.subscription.is_free ) return false;

            return true
        },
	    organizationInitials() {
		    let initials = this.$org.name.split(' ').slice(0, 2).map(word => word[0]).join('');
		    return initials.toUpperCase();
	    }
    },
	methods: {
		companyDropdown(value) {
			this.companyDropdownOpen = value;
		}
	}
}
</script>
<style scoped>
.dropdown-menu {
	top: 0 !important;
	margin-top: -1rem !important;
}
</style>
