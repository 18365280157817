const MainHeaderLinks = {
    // Stellensuche: '/dashboard',
    // Arbeitgeber: '/dashboard',
    // Preise: '/dashboard',
    // 'Über apropos.jobs': '/dashboard',
    dashboard: '/dashboard',
    match: '/match'
}

const SubHeaderLinks = {
    // Dashboard: '/dashboard',
    // 'Übersicht Profile': '/dashboard',
    'job-profiles': '/job-offer',
    //'job-profile-create': '/job-offer/create',
    'pricing': '/organization/pricing',
}

export {
    MainHeaderLinks,
    SubHeaderLinks
}