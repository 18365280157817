<template>
    <div class="app d-flex flex-column h-100">
        <div v-if="$nuxt.isOffline" class="nuxt-offline d-flex align-items-center">
            <o-icon :icon="['far', 'exclamation-triangle']" class="mr-3 mr-md-2" />
            {{ $t('app.is-offline') }}
        </div>
        <mobile-sidebar />
        <main class="main-wrapper pb-5" :class="{ 'pushed': $store.state.sidebarOpen }">
            <header-component />
<!--            <org-subheader v-if="$org" />-->

            <o-container class="px-05 px-md-0 py-5">
                <nuxt keep-alive :keep-alive-props="{ max: 2 }" />
            </o-container>
        </main>

        <notification-slideout-component
            :open="$store.state.notification.isSlideoutOpen"
            @close="$store.dispatch('notification/toggleSlideout')" />

        <toast-container-component />
        <footer-component />
    </div>
</template>
<script>
import MobileSidebar from '~/components/layout/MobileSidebar.vue'
import HeaderComponent from '~/components/header.vue'
import OrgSubheader from '~/components/layout/OrgSubheader.vue'
import ToastContainerComponent from '~/components/toast-container.vue'
import NotificationSlideoutComponent from '~/components/notification/slideout.vue'
import FooterComponent from '~/components/footer.vue'

export default {
	head() {
		return {
			meta: [
				{ hid: 'robots', name: 'robots', content: 'noindex, nofollow' }
			]
		}
	},

    middleware: [
        'outdatedBrowser',
        'closeSidebarOnSmallDevices'
    ],

    components: {
        MobileSidebar,
        HeaderComponent,
        OrgSubheader,
        ToastContainerComponent,
        FooterComponent,
        NotificationSlideoutComponent
    },

	mounted() {
        document.addEventListener('click', this.closeSidebarOnSmallDevices)

        //? trying to fix a sentry error: Notification.requestPermission() is not a function
        if ('Notification' in window && typeof Notification.requestPermission === 'function') {
            if ( Notification.permission !== "denied" ) {
                setTimeout(() => {
                // Notification.requestPermission(result => {
                //     this.$store.commit('notification/setNotificationsPermission', result)
                // })
                Notification.requestPermission().then((result) => {
                    this.$store.commit('notification/setNotificationsPermission', result)
                });
            }, 8000)
            }
        }

        if (this.$breakpoint('lg')) {
            // this.setupChatlio()
        }
    },

    destroyed() {
        document.removeEventListener('click', this.closeSidebarOnSmallDevices)
    },

    methods: {
        closeSidebarOnSmallDevices() {
            this.$store.dispatch('closeOnSmallDevices')
        },

        setupChatlio() {
            window._chatlio = window._chatlio || [];
            let t = document.getElementById("chatlio-widget-embed")
            if (t && window.ChatlioReact && _chatlio.init) {
                return void _chatlio.init(t, ChatlioReact)
            }

            for (var e = function (t) { return function () { _chatlio.push([t].concat(arguments)) } }, i = ["configure", "identify", "track", "show", "hide", "isShown", "isOnline", "page", "open", "showOrHide"], a = 0; a < i.length; a++)_chatlio[i[a]] || (_chatlio[i[a]] = e(i[a])); var n = document.createElement("script"), c = document.getElementsByTagName("script")[0]; n.id = "chatlio-widget-embed", n.src = "https://w.chatlio.com/w.chatlio-widget.js", n.async = !0, n.setAttribute("data-embed-version", "2.3");
                n.setAttribute('data-widget-id', '1c590307-c475-4c1e-652a-05db5ca58def');
                c.parentNode.insertBefore(n, c);

            this.$nextTick(() => {
                window._chatlio.identify(this.$user.id, {
                    name: this.$user.fullName,
                    email: this.$user.email,
                    org: this.$org ? this.$org.name : null
                })
            })
        },
    },

    watch: {
        '$nuxt.isOffline'(isOffline) {
            if (isOffline) {
                this.$toast(this.$t('app.went-offline'), 'warning', { icon: 'wifi-slash' })
            } else {
                this.$toast(this.$t('app.went-online'), 'success', { icon: 'wifi' })
            }
        }
    }
}
</script>
