import Storage from '~/util/storage'

export const state = () => {
    return {
        unread: [],
        isSlideoutOpen: false,
        notificationsPermission: Storage.get('notifications-permission')
    }
}

export const mutations = {
    setNotifications(state, notifications = []) {
        state.unread = notifications
    },

    removeNotification(state, notification) {
        state.unread.splice(state.unread.indexOf(notification), 1)
    },

    setIsSlideoutOpen(state, open) {
        state.isSlideoutOpen = open
    },

    setNotificationsPermission(state, permission) {
        state.notificationsPermission = permission
        Storage.set('notifications-permission', permission)
    }
}

export const actions = {
    fetch({ commit }) {
        return this.$axios.$get('user/notification').then(data => {
            commit('setNotifications', data)
        })
    },

    dismiss({ commit }, notification) {
        return this.$axios.$get(`user/notification/${notification.id}/dismiss`).then(data => {
            commit('setNotifications', data)

            if (data.length === 0) {
                commit('setIsSlideoutOpen', false)
            }
        })
    },

    dismissAll({ commit }) {
        return this.$axios.$get(`user/notification/dismiss-all`).then(data => {
            commit('setNotifications', [])
            commit('setIsSlideoutOpen', false)
        })
    },

    toggleSlideout({ commit, state }) {
        commit('setIsSlideoutOpen', !state.isSlideoutOpen)
    }
}