<template>
    <o-dropdown>
        <template #toggle>
            <o-button type="secondary">
                <span>
                    <o-icon :icon="['fas', 'link']" />
                    {{ $t('general.share') }}
                </span>
            </o-button>
        </template>

        <o-dropdown-header>{{ $t('general.share') }}</o-dropdown-header>
        <o-dropdown-item @click="copy()" icon="clipboard">{{ $t('share.copy-current-url') }}</o-dropdown-item>

	    <template v-if="showSocialMedia">
		    <div class="dropdown-divider"></div>
		    <o-dropdown-header>{{ $t('general.social-media') }}</o-dropdown-header>
		    <o-dropdown-item @click="shareToSocialMedia('Facebook')" icon="facebook-f" icon-style="fab">Facebook</o-dropdown-item>
		    <o-dropdown-item @click="shareToSocialMedia('Twitter')" icon="twitter" icon-style="fab">Twitter</o-dropdown-item>
		    <o-dropdown-item @click="shareToSocialMedia('LinkedIn')" icon="linkedin-in" icon-style="fab">LinkedIn</o-dropdown-item>
		    <o-dropdown-item @click="shareToSocialMedia('WhatsApp')" icon="whatsapp" icon-style="fab">WhatsApp</o-dropdown-item>
	    </template>
    </o-dropdown>
</template>

<script>
export default {
    props: {
		showSocialMedia: {
			type: Boolean,
			default: false,
		},
        value: {
            required: false,
            type: String,
        },
    },
    computed: {
        isProduction () {
            return process.env.NODE_ENV == 'production';
        }
    },
    methods: {
        copy() {
            if (!window.isSecureContext && this.isProduction) {
                this.$store.dispatch('toast/push', {
                    title: this.$t('toast.https-required'),
                    type: 'error',
                })
            } else {

            navigator.clipboard
                .writeText(this.value ? this.value : window.location.href)
                .then(() => {
                    this.$store.dispatch('toast/push', {
                        title: this.$t('toast.link-copied'),
                        type: 'success',
                    })
                })
            }
        },
	    shareToSocialMedia(platform) {
		    // const currentUrl = this.value ? this.value : window.location.href;
		    const currentUrl = 'https://app.apropos.jobs/job-offer/46/social-media-marketing-consultant';
		    const encodedUrl = encodeURIComponent(currentUrl);

		    let shareUrl = '';

		    switch (platform) {
			    case 'Facebook':
				    shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
				    break;
				case 'Twitter':
				    shareUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}`;
				    break;
				case 'LinkedIn':
				    shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}`;
				    break;
				case 'WhatsApp':
				    shareUrl = `https://api.whatsapp.com/send?text=${encodedUrl}`;
				    break;
			    default:
				    console.error('Unsupported platform for sharing');
				    return;
		    }

		    window.open(shareUrl, '_blank');
	    },
    },
}
</script>
