<template>
    <div class="d-none d-lg-block subheader w-100 bg-light">
        <div class="header-container container">
            <o-header-group brand>
                <organization-dropdown v-if="$org" class="pt-1" icon />
                <div class="subheader-links pt-1">
                    <router-link v-for="(link, label) in links" :key="label" :to="link">{{ $t('link.' + label) }}</router-link>
                </div>
            </o-header-group>
            <o-header-group class="mr-0">
                <a v-if="$org" href="https://calendly.com/apropos-jobs/beratung" target="_blank" :title="$t('link.get-support-title')" class="small pt-1">
                    <o-icon :icon="['far', 'phone']" class="mr-1" />
                    {{ $t('link.get-support') }}
                </a>
            </o-header-group>
        </div>
    </div>
</template>
<script>
import { SubHeaderLinks } from '~/components/links'
import OrganizationDropdown from '~/components/organizationDropdown'

export default {
    components: {
        OrganizationDropdown
    },
    
    data () {
        return {
            links: null
        }
    },

    created () {
        this.links = SubHeaderLinks
    }
}
</script>